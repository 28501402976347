.hr-search {
  position: relative;
  width: 100%;
  margin-right: 10px;
  max-width: 657px;

  .hr-search-background {
    position: fixed;
    width: 100vw;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba($hr-gray, 0.75);
    animation: searchResultsAnimation 0.35s cubic-bezier(0, 0, 0.2, 1);
  }

  .hr-search-results {
    animation: searchResultsAnimation 0.35s cubic-bezier(0, 0, 0.2, 1);
    // transform-origin: top center;
    position: absolute;
    z-index: 6;
    width: 100%;
    margin-top: 5px;
    background: $hr-white;
    border: $hr-border;
    box-sizing: border-box;
    box-shadow: $hr-shadow-z0;
    border-radius: $hr-border-radius;
    padding: 15px 20px 15px 20px;

    .results {
      position: relative;
      max-height: 438px;
      overflow: auto;
      padding-right: 35px;

      .item:not(:last-child) {
        border-bottom: 1px solid $hr-light-gray;
        width: 100%;
      }
    }

    .no-results {
      padding: 25px 20px 40px;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $hr-text-color;
      }
    }
  }
}

.hr-search-bar {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  width: 100%;
  background: $hr-white;
  border: $hr-border;
  box-sizing: border-box;
  box-shadow: $hr-shadow-z0;
  border-radius: $hr-border-radius;
  min-height: 50px;
  max-height: 50px;
  padding: 0 20px;

  &.disabled {
    opacity: 0.5;
  }

  svg-icon {
    margin-right: 10px;
  }

  svg {
    height: 17.5px;
    width: 17.5px;
    fill: $hr-text-color;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $hr-text-color;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $hr-text-color;
    }
  }
}

@keyframes searchResultsAnimation {
  0% {
    opacity: 0;
    // transform: rotateX(-90deg);
  }
  // 70% {
  //   transform: rotateX(-20deg);
  // }
  100% {
    opacity: 1;
    // transform: rotateX(0deg);
  }
}

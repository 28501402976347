.hr-button-dropdown-menu + * .mat-menu-panel {
  margin: 10px 0;
  border-radius: $hr-border-radius;
  background: $hr-white;
  border: $hr-border;
  box-shadow: $hr-shadow-z0;
  box-sizing: border-box;
  transform-origin: right top !important;

  .mat-menu-content {
    padding: 0;

    .mat-menu-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 20px;
      height: unset;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $hr-light-gray;
        border: $hr-border;
        box-sizing: border-box;
        margin-right: 10px;
        border-radius: 50%;
        width: 25px;
        height: 25px;

        svg {
          fill: $hr-text-color;
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $hr-text-color;
      }

      &:hover {
        background-color: transparent;
        .icon {
          background-color: $hr-pink;
          svg {
            fill: $hr-white;
          }
        }
        span {
          color: $hr-pink;
        }
      }
    }

    .mat-menu-item:not(:last-of-type) {
      border-bottom: $hr-border;
    }
  }
}

.hr-menu + * .mat-menu-panel {
  margin: 5px 0;
  border-radius: $hr-border-radius;
  background: $hr-white;
  border: $hr-border;
  box-shadow: $hr-shadow-z0;
  box-sizing: border-box;
  max-width: unset !important;

  .mat-menu-content {
    min-width: 320px !important;
    padding: 8px 0;

    .menu-title {
      padding: 16px 25px;
      height: unset;
      border-bottom: $hr-border;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      margin-top: -8px;
    }

    .mat-menu-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 14px 25px;
      height: unset;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-right: 21px;
        border-radius: 50%;
        min-width: 25px;
        min-height: 25px;

        svg {
          fill: $hr-text-color;
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: $hr-text-color;
      }

      &:hover {
        background-color: transparent;
        .icon {
          svg {
            fill: $hr-pink;
          }
        }
        span {
          color: $hr-pink;
        }
      }
    }

    .mat-menu-item:not(:last-of-type) {
      border-bottom: $hr-border;
    }
  }
}

.hr-header-menu + * .mat-menu-panel {
  margin: 5px 0;
  border-radius: $hr-border-radius;
  background: $hr-white;
  border: $hr-border;
  box-shadow: $hr-shadow-z0;
  box-sizing: border-box;
  max-width: unset !important;

  .mat-menu-content {
    width: unset !important;
    padding: 0;

    .menu-title {
      padding: 16px 25px;
      height: unset;
      border-bottom: $hr-border;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
    }

    .modules {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      padding: 30px;

      .module {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 10px 0;
        width: 96px;
        height: 131px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 2px solid transparent;
        cursor: pointer;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 65px;
          height: 65px;
          background-color: $hr-light-gray;
          margin-bottom: 10px;
          border-radius: 50%;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }

        &:hover {
          border-color: $hr-light-gray;
        }
      }
    }

    .routes {
      display: flex;
      flex-direction: column;

      .route {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 16px 100px 16px 35px;
        cursor: pointer;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 19px;
        }

        span {
          white-space: nowrap;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
        }

        &:hover > span {
          color: $hr-pink;
        }
      }

      .route:not(:last-of-type) {
        border-bottom: $hr-border;
      }
    }
  }
}

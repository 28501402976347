@mixin button($button-bg-color, $button-hover-bg-color) {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  height: 50px;
  box-sizing: border-box;
  background: $button-bg-color;
  border-radius: $hr-border-radius;
  border: none;
  transition: all 250ms ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover,
  &.hr-button-hover {
    background-color: $button-hover-bg-color;
    @media screen and (max-width: $hr-breakpoint-tablet) {
      background-color: var(--primary);
    }
  }

  svg-icon {
    margin-right: 20px;

    & > * {
      fill: $hr-white;
    }
  }

  span {
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $hr-white;
  }

  &.hr-button-medium {
    height: 46px;

    svg-icon {
      margin-right: 15px;
    }
  }

  &.hr-button-small {
    height: 42px;

    svg-icon {
      margin-right: 15px;
    }
  }

  .loader {
    font-size: 3px !important;
  }
}

button.hr-button-primary {
  @include button(var(--primary), var(--primary-dark));

  .loader {
    margin: auto;
    font-size: 3px;
    position: relative;
    border-top: 1em solid rgba(255, 255, 255, 0.2);
    border-right: 1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1em solid rgba(255, 255, 255, 0.2);
    border-left: 1em solid $hr-white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .loader,
  .loader:after {
    margin-top: -6px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  &:disabled {
    cursor: default;
    background-color: $hr-input-field-gray;

    &:hover {
      background-color: $hr-input-field-gray;
    }
  }
}

button.hr-button-secondary {
  @include button($hr-gray, $hr-gray-darken);

  svg-icon {
    margin-right: 15px;
  }
}

button.hr-button-primary-outline {
  @include button($hr-white, rgba($hr-pink, 0.15));
  border: 2px solid var(--primary);

  svg-icon {
    & > * {
      fill: var(--primary);
    }
  }

  span {
    color: var(--primary);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    border-color: $hr-text-medium-gray;

    span {
      color: $hr-text-medium-gray;
    }

    &:hover {
      background-color: $hr-white;
    }
  }
}

button.hr-button-secondary-outline {
  @include button(transparent, rgba($hr-gray, 0.15));
  border: 2px solid $hr-gray;

  svg-icon {
    margin-right: 15px;

    & > * {
      fill: $hr-gray;
    }
  }

  span {
    color: $hr-gray;
  }
}

button.hr-button-dropdown {
  position: relative;

  &.hr-button-primary,
  &.hr-button-primary-outline {
    svg-icon {
      margin-right: 0;
      margin-left: 20px;
    }
  }

  &.hr-button-secondary,
  &.hr-button-secondary-outline {
    svg-icon {
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

button.hr-button-rounded {
  @include button($hr-onboarding-gray, var(--primary));
  padding: 9px 20px;
  border-radius: 50px;
  height: 36px !important;
  width: 36px !important;

  span {
    color: $hr-text-color;
    font-weight: normal !important;
  }

  &:hover,
  &.hr-button-hover {
    span {
      color: $hr-white;
    }
  }

  &:disabled {
    opacity: 0.25;
    background-color: $hr-onboarding-gray !important;
  }

  &.hr-button-icon {
    border-radius: 50%;
    width: 36px !important;
    padding: 9px;

    svg-icon {
      margin: 0;

      & > * {
        fill: $hr-text-color;
      }
    }

    &:hover,
    &.hr-button-hover {
      svg-icon {
        & > * {
          fill: $hr-white;
        }
      }
    }

    &:disabled {
      svg-icon {
        & > * {
          fill: $hr-text-color !important;
        }
      }
    }
  }
}

button.hr-tab-button {
  @include button($hr-onboarding-gray, var(--primary));

  width: 149px;
  height: 39px;
  font-size: 12px;
  line-height: 15px;
  padding: 0;
  font-weight: normal;
  background: transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, font-weight 0.15s ease-out;
  cursor: pointer;

  &:hover,
  &.hr-button-hover {
    background-color: transparent;
  }
}

.hr-tab-selected button.hr-tab-button {
  background: $hr-onboarding-gray;
  font-weight: bold;
  transition: background-color 0.25s ease-out, font-weight 0.15s ease-out;
}

button.hr-button-tertiary,
button.hr-button-tertiary-action {
  @include button($hr-gray, $hr-gray-darken);

  padding: 16px 26px;
  background: $hr-white;
  border: $hr-border;
  box-shadow: $hr-shadow-btn;

  span {
    font-weight: normal;
    transition: color 0.25s ease-out;
    color: $hr-text-color;
  }

  svg-icon {
    margin-right: 20px;

    & > * {
      fill: $hr-text-color;
      transition: fill 0.25s ease-out;
    }
  }

  &:hover,
  &.hr-button-hover,
  &.hr-button-active {
    background-color: $hr-white;

    span {
      font-weight: normal;
      color: var(--primary);
      transition: color 0.25s ease-out;
    }

    svg-icon {
      & > * {
        fill: var(--primary);
        transition: fill 0.25s ease-out;
      }
    }
  }

  &.hr-button-active {
    border-color: var(--primary);
    box-shadow: none;
  }

  &:disabled {
    background: $hr-white;
    border-color: $hr-input-field-gray;
    cursor: default;

    span {
      color: $hr-input-field-gray;
    }

    svg-icon > * {
      fill: $hr-input-field-gray;
    }

    &:hover,
    &.hr-button-hover {
      span {
        color: $hr-input-field-gray;
      }

      svg-icon > * {
        fill: $hr-input-field-gray;
      }
    }
  }
}

button.hr-button-tertiary-action {
  svg-icon {
    margin-left: 20px;
    margin-right: 0;
  }
}

button.hr-button-dark-gray {
  padding: 16px 46px;
  background: $hr-gray;
  border-radius: 5px;
  border: 1px solid $hr-gray;
  cursor: pointer;
  box-shadow: 0px 1px 3px $hr-onboarding-gray;
  transition: all 250ms ease-in-out;

  color: #fffbfb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    background: #646e79;
  }
}

button.hr-button-transparent {
  padding: 16px 34px;
  background: transparent;
  border: 1px solid $hr-gray;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 5px;
  filter: drop-shadow(0px 1px 3px $hr-onboarding-gray);
  transition: all 250ms ease-in-out;

  color: $hr-gray;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    background: rgba(118, 129, 142, 0.15);
  }
}

.hr-add-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  .label {
    color: var(--primary);
    margin-left: 10px;
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 99px;
    mat-icon {
      color: white;
    }
  }
}

button.hr-button-primary.hr-button-icon {
  width: 36px;
  height: 36px;
  border-radius: 3px;
  margin: 7px;
  padding: 0;

  svg-icon {
    margin: 0;
  }
}

button.hr-button-small {
  &.hr-button-icon {
    width: 25px !important;
    height: 25px !important;
    max-width: 25px !important;
    max-height: 25px !important;

    svg-icon,
    svg-icon > * {
      height: 8.75px !important;
      width: 8.75px !important;
    }
  }
}

.hr-btn-full {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background-color: var(--module-color-transparent);

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    margin-right: 7px;
    background-color: var(--module-color-transparent);
    border: none;
    mat-icon {
      color: var(--primary);
    }
    .add-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      background-color: var(--module-color-transparent);
      border-radius: 99px;
      transition: all 0.25s ease-in-out;
      mat-icon {
        color: var(--primary);
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }
    &:hover {
      .add-icon {
        background-color: var(--primary);
        mat-icon {
          color: $hr-white;
        }
      }
    }
  }
  label {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.015em;
  }
}

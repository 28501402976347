@mixin resizePercentageCircle(
  $circle-width,
  $circle-border-width,
  $circle-percentage-font-size,
  $circle-percentage-line-height
) {
  .hr-percentage-circle {
    .c100 {
      width: $circle-width;
      height: $circle-width;
      min-width: $circle-width;
      min-height: $circle-width;
      font-size: $circle-width;

      &:after {
        width: calc(#{$circle-width} - $circle-border-width); // tamanho do circulo - largura da borda
        height: calc(#{$circle-width} - $circle-border-width); // tamanho do circulo - largura da borda
      }

      .percentage {
        font-size: $circle-percentage-font-size;
        line-height: $circle-percentage-line-height;
      }
    }
  }
}

@include resizePercentageCircle(50px, 5px, 14px, 18px);

.hr-percentage-circle {
  display: flex;
  justify-content: center;

  .c100 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $hr-light-gray;
    margin: 0;

    &:after {
      top: unset;
      left: unset;
    }

    .percentage {
      position: absolute;
      z-index: 1;
      font-style: normal;
      font-weight: bold;
    }

    .slice > .bar {
      transition: all 1s ease-out;
    }

    @for $n from 0 through 100 {
      $color: null;
      @if $n <= 35 {
        $color: $hr-chart-low-color;
      } @else if $n > 35 and $n <= 70 {
        $color: $hr-chart-medium-color;
      }

      $color: $hr-chart-high-color !default;

      &.p#{$n} {
        .slice {
          .bar,
          .fill {
            border-color: $color;
          }
        }

        .percentage {
          color: $color;
        }
      }
    }
  }
}

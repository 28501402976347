.cdk-overlay-dark-backdrop,
.hr-modal-backdrop {
  background-color: rgba($hr-gray, 0.75);
}

.cdk-overlay-container .hr-modal-backdrop + .cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
}

.cdk-overlay-container .hr-modal-backdrop-close {
  background-color: rgba($hr-gray, 0.75);
}

.hr-modal {
  position: relative !important;
  height: 90vh;
  width: 80vw;
  max-width: 1200px;

  .mat-dialog-container {
    padding: 0px 20px 30px 50px;
  }

  // sobrescrevendo posição padrão
  .hr-tooltip-content {
    top: 40px; // igual a tamanho do ícone
  }

  .modal-header {
    border-bottom: $hr-border;
    margin-bottom: 30px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 35px;
    overflow: auto;
    max-height: calc(90vh - 240px);
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 50px 50px 30px;
  }

  &.hr-thin-modal {
    max-width: 760px;

    .mat-dialog-container {
      position: relative;
      padding: 0px;
      margin: 0;
      min-height: 100%;

      .hr-modal-close-button {
        z-index: 10;
        top: 15px;
        right: 25px;
      }
    }

    .modal-header {
      border-bottom: $hr-border;
      margin-bottom: 30px;

      h1 {
        padding: 0;
        margin: 30px 0 20px 50px;

        &::before {
          margin-left: 50px;
        }
      }
    }

    .modal-content {
      margin: 0 40px;
      max-height: calc(90vh - 260px);
      padding: 0 10px;
    }

    .modal-actions {
      padding: 40px 50px 30px;
    }

    @media screen and (max-width: $hr-breakpoint-tablet) {
      max-width: unset !important;
    }
  }
}

.hr-modal-close-button {
  background-color: $hr-onboarding-gray;
  width: 40px;
  height: 40px;
  position: absolute !important;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  cursor: pointer;

  svg-icon > * {
    fill: $hr-text-color;
  }

  &:hover {
    background-color: var(--primary) !important;

    svg-icon > * {
      fill: $hr-white !important;
    }
  }
}

.hr-extended-modal {
  width: calc(100vw - 100px);
  max-width: unset !important;
  margin: 0 auto;
  max-height: calc(100% - 100px);
  height: unset;

  .mat-dialog-container {
    position: relative;
    padding: 0px;
    margin: 0;
    min-height: 100%;

    .hr-modal-close-button {
      z-index: 10;
      top: 14px;
      right: 14px;
    }
  }

  .modal-header {
    border-bottom: $hr-border;
    margin-bottom: 30px;

    h1 {
      padding: 0 50px 0;
      margin: 30px 0 40px 35px;

      &::before {
        margin-left: 50px;
      }
    }
  }

  .modal-content {
    margin: 0 20px;
    max-height: unset;
    padding: 0 0 40px;
  }
  @media screen and (max-width: $hr-breakpoint-medium) {
    .modal-header {
      h1 {
        padding: 0 50px 0 30px;

        &::before {
          margin-left: 30px;
        }
      }
    }

    .modal-content {
      margin: 0;
    }
  }
}

.hr-small-modal {
  width: 378px;
  // height: 421px;
  max-height: 464px;
  margin: 0 auto;

  .mat-dialog-container {
    position: relative;
    padding: 0;
    margin: 0;
    min-height: 100%;
    overflow: visible;

    .hr-modal-close-button {
      z-index: 10;
      top: 10px;
      right: 10px;

      width: 32px;
      height: 32px;

      svg-icon > * {
        fill: $hr-text-color;
        max-width: 10px;
      }
    }
  }

  .modal-header {
    border-bottom: $hr-border;
    margin-bottom: 30px;

    h2 {
      padding: 0 50px 0 0;
      margin: 20px;

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
    }
  }

  .modal-content {
    margin: 0 30px 0 20px;
    max-height: unset;
    padding: 0 0 40px;
    overflow: auto;
  }

  .modal-actions {
    border-top: $hr-border;
    padding: 20px 30px 30px;
  }
}

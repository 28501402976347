.hr-page {
  max-width: $hr-container-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 20px 75px;

  .hr-first-section {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .hr-input-check {
      border-radius: 5px;
      background-color: $hr-white;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);

      mat-icon {
        margin-right: 6px;
        margin-left: 6px;
      }

      input {
        width: 500px;
        height: 50px;
        border-radius: 5px;
        background-color: $hr-white;
        border: none;

        font-size: 1.1 * 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }

  .hr-page-area {
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    background-color: $hr-white;
    margin-top: 10px;
    padding-top: 26px;
    padding-bottom: 26px;
    margin-bottom: 75px;
    display: flex;
    flex-direction: column;

    .hr-page-header {
      display: flex;
      flex-direction: row;

      .heading-material {
        position: absolute;
        display: block;
        width: 14px;
        height: 31px;
      }

      .hr-page-header-text {
        padding-bottom: 11px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;

        .hr-text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        h2 {
          width: 284px;

          font-size: 1.1 * 26px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          word-break: break-all;
          color: $hr-dark-gray;
          margin: 0;
        }

        .hr-span-general {
          height: 18px;

          font-size: 1.1 * 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: left;
          color: $hr-pink;
          margin-left: 4px;
        }
      }
    }
    .hr-page-body {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;

      .hr-not-elements {
        width: 80px;
        height: 16px;

        font-size: 1.1 * 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 15px;
      }

      .hr-span-body {
        width: 80px;
        height: 16px;

        font-size: 1.1 * 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 15px;
      }

      .hr-opcao-page {
        width: 100%;
        height: 75px;
        border-radius: 10px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: $hr-white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.1%;
        // padding: 19px;
        cursor: pointer;
        transition: box-shadow 0.25s ease-in-out;

        &:hover {
          box-shadow: $shadow-list-item;
          transition: box-shadow 0.25s ease-in-out;
        }

        &.hr-page-padrao {
          border: solid 2px $hr-pink;
        }
      }
    }
  }
}

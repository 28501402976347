ckeditor {
  h1 {
    &::before {
      width: auto;
      top: 0;
      height: 0;
      background-image: none;
    }
  }
}

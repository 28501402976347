.hr-section-page {
  box-sizing: border-box;
  padding: 50px 20px 75px;
  display: flex;
  flex-direction: column;
  max-width: $hr-container-max-width;
  margin: auto;
}

hr-preview {
  width: 90%;
  margin: -40px -30px -60px 0;

  @media screen and (max-width: $hr-breakpoint-small) { 
    width: 100%;
    margin: 0;
  }
}

.hr-section-page-card {
  background: $hr-white;
  box-shadow: $hr-shadow-z0;
  //border: $hr-border;
  border-radius: $hr-border-radius;
  padding: 0;

  .hr-section-page-card-tabs {
    border-bottom: 1px solid $hr-light-gray;
    display: flex;
    height: 60px;
    width: 100%;

    .hr-section-page-card-tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 23px;
      border-bottom: 3px solid transparent;
      transition: border-color 0.25s ease-out;

      .hr-tab-button {
        &:hover {
          transition: background-color 0.25s ease-out;
          background: $hr-onboarding-gray;
        }
      }

      // &:before {
      //   content: '';
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 0%;
      //   height: 100%;
      //   background-color: darken($color, 15%);
      //   transition: all .3s;
      //   border-radius: 10rem;
      //   z-index: -1;
      // }

      &.hr-tab-selected {
        color: $hr-pink;
        border-bottom-color: $hr-pink;
        transition: border-color 0.25s ease-out;
      }
    }
  }
}

.hr-section-card-content {
  display: flex;
  flex-direction: column;
  margin: 0 35px 52px;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid $hr-light-gray;
  box-shadow: 0px 1px 4px rgba(21, 27, 38, 0.08);
  border-radius: 5px;

  .hr-card-content-header {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -0.02em;
  }

  .hr-card-content-area {
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.hr-section-page-card-actions {
  display: flex;
  align-self: center;
  position: absolute;
  right: 0;
  margin-right: 35px;

  & > * {
    margin-left: 15px;
  }
}

.hr-section-page-card-draft {
  display: flex;
  align-self: center;
  align-items: center;

  height: 36px;
  min-width: 148px;

  margin-left: 30px;
  padding: 12px 16px;
  border-radius: 5px;

  background: #f2ebfc;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }

  svg-icon {
    margin-right: 8.75px;
    max-width: 16px;

    svg {
      fill: $hr-text-color;
    }
  }
}

@mixin resizeIcon($size) {
  .icon {
    min-width: $size;
    max-width: $size;
    min-height: $size;
    max-height: $size;
  }
}

@mixin resizeInfo($font-size, $line-height) {
  .info > .primary,
  .secondary {
    font-size: $font-size;
    line-height: $line-height;
  }
}

.hr-group-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  //min-width: 270px;
  margin-left: 20px;

  @include resizeIcon(50px);
  @include resizeInfo(14px, 18px);

  .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $hr-onboarding-gray;
    box-sizing: content-box;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $hr-onboarding-gray;
    border-radius: 50%;
    margin-right: 15px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .primary {
      color: $hr-text-color;
      font-style: normal;
      font-weight: bold;
    }

    .secondary {
      color: $hr-text-color;
      font-style: normal;
      font-weight: normal;
      word-break: break-word;
      margin-top: 3px;
    }
  }

  @media screen and (min-width: $hr-breakpoint-medium) {
    @include resizeIcon(60px);
    @include resizeInfo(16px, 20px);
  }
}

.hr-message-box {
  display: flex;
  flex-direction: column;
  background-color: var(--module-color-transparent);
  font-size: 14px;
  line-height: 18px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: $hr-border-radius;

  > span {
    margin-bottom: 10px;
  }

  .message-title {
    font-weight: bold;
  }
}

.ol-template-back-bar {
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: var(--primary);
}

.ol-template-slide-data {
  position: relative;
  border-radius: 5px;
  background-color: $hr-white;
  padding: 0;
  height: calc(100vh - 152px);
  width: 1200px;
  min-height: 400px;
  margin: 40px auto 81px;
  box-shadow: 0px 4px 4px rgba(219, 214, 214, 0.25);
  border: $hr-border-2;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: $hr-breakpoint-small) {
    margin: 40px 50px;
    width: calc(100% - 100px);
  }

  @media screen and (max-width: $hr-breakpoint-tablet) {
    width: 100%;
    height: auto;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ol-template-slide-info {
    padding: 0 20px 20px 20px;

    .ol-template-slide-title {
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      line-height: 43px;
      color: var(--primary);
      margin: 0 17% 30px;

      @media screen and (max-width: $hr-breakpoint-medium) {
        font-size: 30px;
        line-height: 38px;
        margin: 0 15% 30px;
      }

      @media screen and (max-width: $hr-breakpoint-small) {
        font-size: 28px;
        line-height: 35px;
        margin: 0 86px 30px 98px;
      }

      @media screen and (max-width: $hr-breakpoint-tablet) {
        font-size: 22px;
        line-height: 150%;
        margin: 0 0 10px;
      }
    }

    .ol-template-slide-description {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      margin: 0 17% 18px;

      @media screen and (max-width: $hr-breakpoint-medium) {
        font-size: 14px;
        margin: 0 15% 30px;
      }

      @media screen and (max-width: $hr-breakpoint-small) {
        font-size: 14px;
        margin: 0 86px 40px 98px;
      }

      @media screen and (max-width: $hr-breakpoint-tablet) {
        font-size: 16px;
        margin: 0 0 30px;
      }
    }
  }
}

.hr-edit-mode {
  background-image: none !important;
  border-radius: $hr-border-radius;
  width: 100%;
  max-width: unset;
  max-height: unset;
  height: unset;

  .form-area {
    padding: 30px 72px 30px 30px;
    width: 100%;

    h2 {
      margin: 0 0 40px;
    }
  }
}

.hr-midia-input-area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  hr-form-field-image,
  hr-form-field-video {
    width: calc(50% - 15px);
  }
}

.ol-info-box {
  background-color: var(--primary-transparent);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(232, 236, 238, 0.1));
  border-radius: $hr-border-radius;
  padding: 21px 18px;
  margin-bottom: 12px;
  line-height: 150%;
}

// .preview-mode {
//   &.hr-slide-area {
//     width: 195px;
//     height: 112px;
//     border-radius: 5px;
//   }

//   .ol-template-back-bar {
//     height: 49px;
//   }

//   .ol-template-slide-data {
//     border-radius: 1px;
//     margin: 5px auto auto;
//     overflow: hidden;
//     width: 122px;
//     min-height: 96px;

//     .ol-template-slide-title {
//       font-size: 4px;
//       line-height: 5px;
//       margin: 0 15% 4px;
//     }

//     .ol-template-slide-description {
//       font-weight: normal;
//       font-size: 2px;
//       line-height: 150%;
//       margin: 0 15% 3px;
//     }
//   }
// }

.hr-edit-mode {
  background-image: none !important;
  border-radius: $hr-border-radius;
  width: 100%;
  max-width: unset;
  max-height: unset;
  height: unset;

  .form-area {
    padding: 30px 72px 30px 30px;
    width: 100%;

    h2 {
      margin: 0 0 40px;
    }
  }
}

.hr-midia-input-area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  hr-form-field-image,
  hr-form-field-video {
    width: calc(50% - 15px);
  }
}

@import '../variables.scss';

/* Overlay */
.jwplayer.jw-skin-hrestart.jw-state-idle .jw-controls {
  background: rgba($hr-dark-gray, 0.75);
}

/* Botões no modo idle */
.jwplayer.jw-skin-hrestart.jw-state-idle .jw-display-controls .jw-display-icon-container {
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid $hr-white;
  width: 48pt;
  height: 48pt;
}

.jwplayer.jw-skin-hrestart.jw-state-idle .jw-display-controls .jw-display-icon-container .jw-icon {
  color: $hr-white;
  height: 24pt;
  width: 24pt;
  padding: 12pt;
}

.jwplayer.jw-skin-hrestart.jw-state-idle .jw-display-container {
  vertical-align: middle;
}

/* Botões durante reprodução */
.jwplayer.jw-skin-hrestart .jw-display-controls .jw-display-icon-container .jw-icon {
  color: $hr-ice;
  width: 48pt;
  height: 48pt;
}

/* Overlay durante pause */
.jwplayer.jw-skin-hrestart.jw-state-paused .jw-controls {
  background-color: rgba($hr-dark-gray, 0.25);
}

/* Barra de controle e ícones no pause */
.jwplayer.jw-skin-hrestart .jw-controlbar {
  background-color: transparent;
}

.jwplayer.jw-skin-hrestart .jw-controlbar .jw-button-container .jw-button-color {
  color: $hr-ice;
}

.jwplayer.jw-skin-hrestart.jw-state-paused .jw-controlbar .jw-button-container .jw-button-color {
  color: $hr-ice;
}

.jwplayer.jw-skin-hrestart .jw-controlbar .jw-button-container .jw-button-color:hover {
  color: $hr-white;
}

/* Tooltips */
.jwplayer.jw-skin-hrestart .jw-controlbar .jw-tooltip.jw-open {
  border-radius: 5px;
  color: $hr-dark-gray;
}

.jwplayer.jw-skin-hrestart .jw-controlbar .jw-tooltip .jw-text {
  background-color: $hr-dark-gray;
  color: $hr-ice;
  border-radius: 5px;
  border: 0;
}

/* Knob do volume */
.jwplayer.jw-skin-hrestart .jw-controlbar .jw-slider-volume .jw-slider-container .jw-progress {
  background-color: var(--primary);
}

.jwplayer.jw-skin-hrestart .jw-overlay .jw-slider-volume .jw-slider-container .jw-knob {
  background-color: var(--primary);
}

/* Cores dos sliders */
.jwplayer.jw-skin-hrestart .jw-controlbar .jw-slider-time .jw-slider-container .jw-buffer {
  background-color: $hr-ice;
}

.jwplayer.jw-skin-hrestart .jw-controlbar .jw-slider-time .jw-slider-container .jw-progress {
  background-color: var(--primary);
}

.jwplayer.jw-skin-hrestart .jw-controlbar .jw-slider-time .jw-slider-container .jw-knob {
  background-color: var(--primary);
}

/* Menu de configurações */
.jwplayer.jw-skin-hrestart .jw-settings-menu {
  background-color: transparent;
}

.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-topbar {
  background-color: $hr-dark-gray;
}

.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-topbar .jw-icon:hover {
  color: var(--primary);
}

.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-submenu {
  background-color: rgba($hr-dark-gray, 0.75);
}

.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-content-item {
  color: $hr-ice;
}

.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-content-item:hover,
.jwplayer.jw-skin-hrestart .jw-settings-menu .jw-settings-item-active {
  color: var(--primary);
  font-weight: bold;
}

.jw-preview {
  background-color: var(--primary-transparent);
}

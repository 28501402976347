::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: $hr-gray;
  border: 1px solid $hr-gray;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
  background: $hr-light-gray;
  border: 1px solid $hr-light-gray;
  box-sizing: border-box;
  border-radius: 50px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@import '../../variables.scss';

.hr-none-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 956px;
  margin: 10px 35px 35px 35px;
  background: #f5f6f7;
  border-radius: 5px;

  .hr-none-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 222px;
    height: 222px;
    margin-bottom: 50px;
    background: #fdfdfd;
    border-radius: 999px;
  }

  .hr-none-item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.015em;
    }
  }

  .hr-none-item-buttons {
    .hr-button-dark-gray {
      margin-right: 16px;
    }
  }
}

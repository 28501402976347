.hr-float-right {
  float: right;
}

.hr-full {
  width: 100%;
}

.hr-loading {
  animation: loading 1.7s infinite linear;
  background: #f6f7f8 !important;
  background-image: -webkit-linear-gradient(to left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-image: linear-gradient(to left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-repeat: no-repeat;
}

.hr-flex {
  display: flex;
  flex-direction: row;
}

@keyframes loading {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 200px;
  }
}

// estilos para drag and drop
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.skeleton-item {
  opacity: 0.15;
  overflow: hidden;
  animation: loading 1.7s infinite linear;
  background: $hr-gray;
  background-image: -webkit-linear-gradient(to left, $hr-gray 0%, $steel-tint 20%, $hr-gray 40%, $hr-gray 100%);
  background-image: linear-gradient(to left, $hr-gray 0%, $steel-tint 20%, $hr-gray 40%, $hr-gray 100%);
  background-repeat: no-repeat;
  height: 20px;
}

.show-infos-area {
  cursor: pointer;
  position: relative;
  height: fit-content;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  position: relative;
  margin-left: 10px;

  .mat-icon {
    color: $hr-gray;
  }

  .tooltiptext {
    left: -35px;
  }
}

.infos-box-area {
  position: relative;

  .infos-box {
    background-color: $light-steel-opaque;
    width: 100%;
    font-size: 14px;
    line-height: 1.5;
    font-weight: normal;
    text-align: left;
    
    padding: 28px 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    position: absolute;
    top: 40px;
    right: -30px;
    width: 400px;
    transition: opacity 200ms ease-in-out;
    opacity: 1;

    &.hr-hidden {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }

    .title {
      font-weight: 600;
    }

    .close-icon {
      position: absolute;
      cursor: pointer;
      fill: $hr-gray;
      right: 5px;
      top: 5px;
    }
  }
}

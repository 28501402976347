@import '../variables.scss';

.hr-menu-options {
  .mat-menu-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mat-menu-item {
    width: 220px;
    text-align: right;
    vertical-align: middle;
    font-weight: 500;
        padding: 0;
    border-bottom: 1px solid rgba($hr-gray, 0.1);

    &:last-child {
      border-bottom: none;
    }

    svg-icon {
      vertical-align: middle;
      height: 30px;
      width: 24px;
      margin-top: -7px;
      margin-left: 10px;

      svg {
        fill: $hr-text-color;
      }
    }
  }
}

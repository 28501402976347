.hr-slide-area {
  $hr-slide-proportion: 0.57435;

  position: relative;
  display: flex;
  background-color: $hr-white;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  width: 100vw;
  @media screen and (max-width: $hr-breakpoint-tablet) {
    max-height: unset;
    max-width: unset;
    height: 100%;
    padding-bottom: 20px;
  }
}

.preview-mode {
  &.hr-slide-area {
    width: 195px;
    height: 112px;
    border-radius: 5px;
  }
}

@font-face {
  font-family: Circular;
  src: url('/assets/fonts/CircularXX/CircularXX-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url('/assets/fonts/CircularXX/CircularXX-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Circular;
  src: url('/assets/fonts/CircularXX/CircularXX-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@import '../../variables.scss';

.hr-group-list {
  .none-column-groups {
    padding: 30px 35px;
    .none-groups-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.015em;
    }
    .link {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .group-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 30px 16px 16px;
    border-bottom: 1px solid $hr-light-gray;
    .group-title {
      display: flex;
      align-items: center;
      .group-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        margin-right: 10px;
        background: #ffebe5;
        border-radius: 50px;
        mat-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          font-size: 16px;
          color: $hr-pink;
          border-radius: 50px;
          border: 1px solid $hr-purple;
          text-shadow: -1px 0 $hr-purple, 0 1px $hr-purple, 1px 0 $hr-purple, 0 -1px $hr-purple;
        }
      }
      .group-name {
        width: 200px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.015em;
      }
      .qntd-cargo {
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 16px;
        background: #f6f6f6;
        white-space: nowrap;
        transition: all 250ms ease-in-out;
        &:hover {
          background: rgba(118, 129, 142, 0.15);
        }
        .none-cargo {
          cursor: default;
        }
      }
    }
    .hr-section-page-card-actions {
      position: inherit !important;
      margin-right: 0px;
      .menu > button {
        z-index: 1;
        position: inherit;
      }
    }
  }
}

@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './partials_antigos/typography';
@import './partials_antigos/base';
@import './partials_antigos/form';
@import './partials_antigos/modal';
@import './partials_antigos/expansion_panel';
@import './partials_antigos/tab';
@import './partials_antigos/menu';
@import './partials_antigos/carousel';
@import './partials_antigos/utils';
@import './partials_antigos/page';
@import './partials_antigos/listagens';
@import './externals/circle';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@import './abstracts/all';
@import './base/all';
@import './components/all';
@import './layouts/all';
@import './modules/all';

.hr-default-input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $hr-text-color;
  caret-color: $hr-text-color;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $hr-gray;
  }

  @media screen and (max-width: $hr-breakpoint-small) {
    font-size: 14px;
    line-height: 18px;
  }
}

.hr-default-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: color ease-in-out 250ms;
  display: flex;
  align-items: center;

  @media screen and (max-width: $hr-breakpoint-medium) {
    font-size: 14px;
    line-height: 18px;
  }

  &.hr-button-label {
    width: 100%;
    padding: 16px;
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    background: $hr-white;
    border: $hr-border;
    box-sizing: border-box;
    box-shadow: $hr-shadow-btn;
    border-radius: $hr-border-radius;
    transition: $hr-transition;

    &:hover {
      border-color: $hr-text-color;
    }
  }
}

.hr-default-field {
  display: flex;
  align-items: center;
  width: 100%;
  background: $hr-white;
  border: $hr-border;
  border-color: $hr-input-field-gray;
  box-sizing: border-box;
  border-radius: $hr-border-radius;
  height: 50px;
  padding: 0 16px;
  transition: border-color ease-in-out 250ms;

  @media screen and (max-width: $hr-breakpoint-small) {
    padding: 0 26px;
  }

  &:hover,
  &:active {
    border-color: $hr-text-color;
  }
}

.hr-default-field-error {
  display: flex;
  align-items: flex-start;
  opacity: 0;
  transition: opacity ease-in-out 250ms;
  display: none;

  span {
    color: $hr-chart-low-color;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }

  svg-icon {
    transition: opacity ease-in-out 250ms;
    margin: 2px 6px 0px 0px;
  }

  ::ng-deep svg {
    width: 14px;
    height: 14px;
    fill: $hr-chart-low-color;
  }
}

.hr-form-field-focused {
  .hr-default-field,
  &.hr-form-field-textarea .field {
    border-color: $hr-text-color;
  }
}

.hr-form-field-error-background {
  &:not(.hr-form-field-error) {
    position: relative;
    margin-bottom: 30px;
  }

  .hr-default-field-error {
    height: 42px;
    width: 100%;
    background-color: rgba($hr-chart-low-color, 0.05);
    padding: 12px 16px;
    margin: 16px 0 0;
    border-radius: $hr-border-radius;
  }
}

.hr-form-field-readonly {
  label {
    color: $hr-input-field-gray;
  }

  .hr-default-field {
    background-color: $hr-light-gray;
    color: $hr-text-medium-gray;
    border: none;

    input {
      color: $hr-text-medium-gray;

      &::-webkit-input-placeholder {
        color: $hr-text-medium-gray;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: $hr-text-medium-gray;
      }

      &::-moz-placeholder {  /* Firefox 19+ */
        color: $hr-text-medium-gray;
      }

      &:-ms-input-placeholder {
        color: $hr-text-medium-gray;
      }

      &::placeholder {
        color: $hr-text-medium-gray;
      }
    }
  }
}

.hr-form-field-disabled {
  opacity: 0.6;

  input,
  .hr-default-input,
  ::ng-deep .ck.ck-toolbar,
  ::ng-deep .ck-content {
    color: $hr-text-medium-gray !important;
  }
}

.hr-form-field-error {
  &:not(.hr-form-field-error-background) {
    .hr-default-label {
      color: $hr-chart-low-color;
    }
    .hr-default-field,
    .field {
      border-color: $hr-chart-low-color;
      border-width: 2px;
    }
  }

  &.hr-form-field-error-background {
    margin-bottom: 50px;
  }

  &.hr-form-field-radio {
    .hr-default-field-error {
      bottom: -50px;
      margin: 0;
    }

  }

  .hr-default-field-error {
    opacity: 1;
    position: absolute;
    bottom: -14px;
    display: flex;
    margin: 6px 16px -11px 16px;
  }
}

.hr-form-row {
  display: flex;
  flex-direction: row;

  & > .hr-form-col {
    width: 100%;

    &:not(:last-child) {
      padding-right: 15px;
    }
    &:not(:first-child) {
      padding-left: 15px;
    }

    & * {
      max-width: 600px;
    }
  }

  @media screen and (max-width: $hr-breakpoint-small) {
    flex-direction: column;

    & > .hr-form-col {
      margin: 0 auto;
      max-width: 600px;

      &:not(:last-child) {
        padding-right: 0;
      }
      &:not(:first-child) {
        padding-left: 0;
      }
      &:last-of-type:not(:first-child) {
        margin-bottom: 60px;
      }
    }
  }
}

.hr-form-field-date-picker {
  display: flex;
  align-items: center;
  width: 100%;

  .label {
    @extend .hr-default-label;
    font-weight: normal;
    letter-spacing: -0.015em;
    margin-right: 10px;
  }

  .field {
    @extend .hr-default-field;
    cursor: pointer;
    height: 50px;
    padding: 15px 13px;
    background-color: $hr-white;

    input {
      @extend .hr-default-input;
      cursor: pointer;
    }

    ::ng-deep svg-icon {
      margin-left: 10px;
    }

    ::ng-deep svg {
      width: 18px;
      height: 20px;
      fill: $hr-gray;
    }
  }
}

.hr-error {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 70px;
  height: 42px;
  background: $hr-error-background-list;
  border-radius: 5px;
  color: $hr-fail-color;
  font-size: 14px;
  line-height: 18px;
  transition: $hr-transition;

  .error-text {
    margin: auto;
    width: max-content;
    margin-right: 3px;
  }
  .icon-error{
    font-size: 14px;
    margin-left: 23px;
    text-align-last: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .error-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    .fix-error-button {
      width: max-content;
      text-decoration: underline;
      font-weight: 700;
    }
    &:hover {
      color: darken($color: $hr-fail-color, $amount: 20%);
      transition: $hr-transition;
    }
  }
}

@import 'src/styles/variables.scss';

.hr-page-carta-oferta {
  max-width: $hr-container-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 20px 91px;
  .hr-section-page-card {
    padding: 0.1px 0px;
  }
  .hr-first-section-carta-oferta {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .hr-filter-carta-oferta {
      display: flex;
      width: 100%;
      margin-right: 60px;
    }
    .hr-btn.hr-btn-primary {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 266px;
      height: 50px;
      padding: 16px 32px;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      svg-icon {
        margin-right: 10px;
        fill: $hr-white;
      }
    }
  }
  .hr-page-area {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding: 40px 0 62px 0;
    background-color: $hr-white;
    border-radius: 5px;
    box-shadow: 0px 1px 4px rgba(21, 27, 38, 0.08);

    .hr-page-header {
      display: flex;
      flex-direction: row;

      .heading-material {
        position: absolute;
        display: block;
        width: 14px;
        height: 31px;
      }
      .hr-page-header-text-carta-oferta {
        display: flex;
        align-items: center;
        height: 50px;
      }
    }
    .hr-header-order-buttons {
      justify-content: space-between;
      padding-right: 20px;
      align-items: center;
    }
    .hr-page-body-carta-oferta {
      .hr-opcao-page {
        padding: 20px 35px 20px 30px;
        border: 1px solid $hr-light-gray;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px $hr-shadow-z0;
        border-radius: 5px;
        &:hover {
          box-shadow: 0px 1px 4px $hr-shadow-z0;
        }
      }
      .hr-cards-carta-oferta {
        margin: 50px 20px 0 0;
        .hr-card-carta-oferta {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 30px;
          margin: 0px 0px 10px 0px;
          border: 1px solid $hr-light-gray;
          box-sizing: border-box;
          box-shadow: 0px 1px 4px rgba(21, 27, 38, 0.08);
          border-radius: 5px;
          .hr-group-info {
            .icon {
              background-color: #ffefeb;
            }
          }
        }
      }
    }
  }
}


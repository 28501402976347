@import '../variables.scss';

.cdk-overlay-dark-backdrop,
.hr-full-modal-backdrop {
  background: rgba($hr-dark-gray, 0.8);
}

.cdk-overlay-container .hr-full-modal-backdrop + .cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
}

.hr-full-scroll-modal {
  &.hr-modal-medium {
    max-width: 800px !important;
  }

  &.hr-modal-preview {
    max-width: 90vw !important;

    .mat-dialog-container {
      width: 90vw;
    }
  }

  .mat-dialog-container {
    width: 80vw;
    padding: 0;
    margin-top: 65px;
    margin-bottom: 65px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 1440px) {
      width: 1200px !important;
    }
  }

  @media screen and (max-width: 1150px) {
    .hr-modal-header {
      padding: 30px 0px;
    }
  }

  .hr-modal-content {
    padding: 31px 100px !important;

    &.hr-large {
      padding: 31px 30px 130px 30px !important;

      @media screen and (min-width: 735px) {
        padding: 31px 100px 130px 100px !important;
      }

      @media screen and (min-width: 1024px) {
        padding: 31px 200px 130px 200px !important;
      }
    }
  }

  .hr-form-btn .hr-btn-primary {
    min-width: 240px;
    margin: 15px 0 0;
  }
}

.hr-double-column {
  min-width: 1110px;
}

.hr-pastaDigital-scroll-modal {
  &.hr-modal-medium {
    max-width: 800px !important;
  }

  .mat-dialog-container {
    padding: 0 !important;
    margin-top: 65px;
    margin-bottom: 65px;
    position: relative;
    width: 475px;
  }

  .hr-modal-content {
    padding: 31px 100px !important;

    &.hr-large {
      padding: 31px 30px 130px 30px !important;

      @media screen and (min-width: 735px) {
        padding: 31px 100px 130px 100px !important;
      }

      @media screen and (min-width: 1024px) {
        padding: 31px 200px 130px 200px !important;
      }
    }
  }
}

.alertModal {
  position: relative !important;

  .mat-dialog-container {
    padding: 50px 55px;
    max-width: 450px;
  }
}

.warningModal{
  position: relative !important;

  .mat-dialog-container {
    padding: 25px 30px 30px;
    max-width: 450px;
  }
}

.header-modal {
  .mat-dialog-container {
    padding: 21px 50px 35px;
  }
}

.integracao-header-modal {
  .mat-dialog-container {
    padding: 21px 50px 35px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.hr-panel-no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

.cdk-overlay-pane .mat-dialog-container {
  border-radius: 10px;
}

.hr-modal-close {
  z-index: 10;
  position: absolute;
  right: 15px;
  top: 15px;
  background: rgba($hr-gray, 0.1);
  transition: background-color 0.25s ease-in-out;

  svg {
    fill: $hr-dark-gray !important;
  }

  &:hover {
    background: $hr-pink;
    transition: background-color 0.25s ease-in-out;

    svg {
      fill: $warm-pink-contrast !important;
      transition: fill 0.25s ease-in-out;
    }
  }
}

.hr-close-tooltip span {
  position: absolute;
  color: $warm-pink-contrast;
  background: $hr-dark-gray;
  box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);

  font-size: 1.1 * 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.15em;
  visibility: hidden;
  border-radius: 6px;
  padding: 0 15px;
  z-index: 999;
  top: 62px;
  right: 0%;
}

.hr-close-tooltip span:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border-bottom: 3px solid $hr-dark-gray;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
}
.hr-close-tooltip:hover span {
  visibility: visible;
}

.hr-modal-back {
  position: absolute;
  left: 15px;
  top: 28px;
  z-index: 10;

  svg {
    fill: $hr-dark-gray !important;
  }

  .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 1.1 * 20px;
  }

  label {
    position: absolute;
    right: -55px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    cursor: pointer;
  }
}

.hr-modal-header {
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  position: relative;
  border-bottom: 1px solid $light-steel;

  &.hr-tab {
    padding-bottom: 65px;
  }

  &.hr-margin {
    margin-bottom: 25px;
  }

  h1 {
    margin: 0;
    word-break: break-word;
  }
}

.hr-modal-body {
  position: relative;
  z-index: 9;
  padding: 0 70px 60px 70px;

  &.hr-tab {
    margin-top: -70px;
  }

  h2 {
    color: $hr-pink;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  h3 {
    color: $hr-pink;
    margin: 20px 0 12px;
  }

  .hr-col-modal {
    flex-grow: 1;

    .hr-input-half {
      width: 43%;

      @media screen and (max-width: 960px) {
        width: 100%;
        margin: 0;
      }
    }

    .hr-col-half {
      width: calc(50%);

      &:first-of-type {
        border-right: 1px solid rgba($hr-gray, 0.1);
        padding-right: 65px;
      }
      &:last-of-type {
        padding-left: 65px;
      }

      @media screen and (max-width: 960px) {
        width: 100%;
        border: none;
        margin: 0;
        padding: 0;

        &:first-of-type {
          width: 100%;
          border: none;
          margin: 0;
          padding: 0;
        }
        &:last-of-type {
          padding: 0;
        }
      }
    }

    @media screen and (max-width: 960px) {
      &.hr-flex {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

.hr-content {
  h2 {
    color: $hr-pink;
    margin-top: 35px;
    margin-bottom: 9px;
  }

  &.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.hr-modal-grafismo {
  background-image: url('/assets/images/grafismo-rosa_square.png');
  background-repeat: no-repeat;
  width: 650px;
  height: 650px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-size: cover;
  pointer-events: none;
}

@mixin resizeIcon($icon-padding, $icon-width) {
  .icon {
    padding: $icon-padding;
    img {
      height: $icon-width;
      width: $icon-width;
    }
  }
}

@mixin resizeLabel($label-number-font-size, $label-number-line-height) {
  .label {
    .title {
      font-size: $label-number-font-size;
      line-height: $label-number-line-height;
    }
  }
}

@mixin resizePercentageCircle(
  $circle-width,
  $circle-border-width,
  $circle-percentage-font-size,
  $circle-percentage-line-height
) {
  .hr-percentage-circle {
    .c100 {
      width: $circle-width;
      height: $circle-width;
      min-width: $circle-width;
      min-height: $circle-width;
      font-size: $circle-width;

      &:after {
        width: calc(#{$circle-width} - $circle-border-width); // tamanho do circulo - largura da borda
        height: calc(#{$circle-width} - $circle-border-width); // tamanho do circulo - largura da borda
      }

      .percentage {
        font-size: $circle-percentage-font-size;
        line-height: $circle-percentage-line-height;
      }
    }
  }
}

@mixin resizeListItemLabel(
  $label-title-font-size,
  $label-title-line-height,
  $label-description-font-size,
  $label-description-line-height
) {
  .label {
    .title {
      font-size: $label-title-font-size;
      line-height: $label-title-line-height;
    }
    .description {
      font-size: $label-description-font-size;
      line-height: $label-description-line-height;
    }
  }
}

hr-segmented-board {
  border-radius: $hr-border-radius;
  background-color: $hr-white;
  border: $hr-border;
  box-sizing: border-box;
  box-shadow: $hr-shadow-z0;
  width: calc(50% - 26px);
  padding: 30px;
  margin: 0 13px 30px;
  transition: width 0.25s ease-in-out;

  &:last-of-type {
    width: 100%;
  }

  & > .hr-segmented-board-container {
    align-items: center;

    .hr-segmented-board-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      @include resizeIcon(28px, 45px);

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $hr-onboarding-gray;
        border-radius: 50%;
        margin-right: 20px;
      }

      @include resizeLabel(34px, 43px);

      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title {
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.015em;
          padding-right: 150px;
        }
      }

      .action-button {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: 144px;
        padding: 16px 20px;
      }

      @media screen and (max-width: $hr-breakpoint-medium) {
        @include resizeIcon(25px, 32px);
        @include resizeLabel(30px, 34px);
      }
      @media screen and (max-width: $hr-breakpoint-small) {
        .icon {
          margin-right: 0;
        }
        @include resizeIcon(0, 0);
        @include resizeLabel(28px, 34px);
      }
      @media screen and (max-width: $hr-breakpoint-tablet) {
        @include resizeIcon(27px, 45px);
        @include resizeLabel(34px, 43px);

        .icon {
          margin-right: 20px;
        }
      }
    }

    .hr-segmented-board-subtitle {
      margin: 31px 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .summary {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: $hr-onboarding-gray;
        border-radius: 5px;
        padding: 18px 22px;
        width: 100%;

        &.empty-summary {
          height: 96px;
          justify-content: center;
        }

        .spotlight {
          font-weight: 500;
          font-size: 48px;
          line-height: 61px;
          margin-right: 15px;
        }

        .subtitle {
          font-weight: normal;
          font-size: 16px;
          line-height: 120%;
        }
      }

      .hr-search-bar {
        width: 500px;
      }

      @media screen and (max-width: $hr-breakpoint-medium) {
        margin: 50px 0 24px;

        .summary {
          padding: 17px 22px;

          &.empty-summary {
            height: 78px;
          }

          .spotlight {
            font-size: 36px;
            line-height: 45px;
            margin-right: 12px;
          }
        }
      }

      @media screen and (max-width: $hr-breakpoint-small) {
        margin: 34px 0 7px;

        .summary {
          &.empty-summary {
            height: 72px;
          }

          .spotlight {
            font-size: 30px;
            line-height: 38px;
            margin-right: 10px;
          }

          .subtitle {
            font-size: 14px;
          }
        }

        .hr-search-bar {
          width: 369px;
        }
      }

      @media screen and (max-width: $hr-breakpoint-tablet) {
        .summary {
          @include resizeListItemLabel(16px, 20px, 14px, 18px);
        }

        .hr-search-bar {
          width: 500px;
          max-width: 50%;
        }
      }
    }
  }

  &.collapsed {
    // width: calc(33.33% - 25px);
    margin: 0 12.5px 111px;
    transition: width 0.25s ease-in-out;
    width: calc(50% - 26px) !important;

    &:last-of-type {
      width: calc(50% - 26px) !important;
    }

    .hr-segmented-board-title {
      @include resizeIcon(20px, 40px);

      @include resizeLabel(24px, 30px);

      .label {
        max-width: 185px;
      }
    }

    .hr-list {
      height: 0;
      overflow: hidden;
    }

    .hr-segmented-board-subtitle {
      .summary {
        padding: 5px 150px 30px 100px;
        background: transparent;
        display: inline-block;
        margin: 0;

        .spotlight,
        .subtitle {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.015em;
          margin: 0;
        }
      }
    }

    @media screen and (max-width: $hr-breakpoint-medium) {
      // os dois px extras são das margens do first e last (16 divididos 3)
      width: calc(33.33% - 10px - 5.33px);
      margin: 0 13px 65px;
      padding: 25px;

      &:first-of-type {
        margin-right: 13px;
      }

      &:last-of-type {
        margin-right: 13px;
      }

      .hr-segmented-board-title {
        flex-direction: column;
        align-items: flex-start;

        .label {
          max-width: unset;
          .title {
            margin-top: 10px;
            padding: 0;
          }
        }

        .action-button {
          padding: 17px;
          width: auto;

          span {
            width: 0;
            overflow: hidden;
          }

          svg-icon {
            margin: 0;
          }
        }
      }

      .hr-segmented-board-subtitle {
        .summary {
          padding: 15px 0 55px;
        }
      }
    }

    @media screen and (max-width: $hr-breakpoint-small) {
      margin-bottom: 43px;
      padding: 20px;
    }
  }

  &.expanded {
    width: calc(100% - 26px) !important;
    transition: width 0.25s ease-in-out;

    .hr-segmented-board-subtitle {
      .summary {
        padding: 18px 20px;
        max-width: 395px;

        .spotlight {
          margin-right: 14px;
        }
      }
    }

    .hr-list {
      height: 600px;
      overflow: hidden auto;
      padding-right: 35px;
    }

    .hr-list {
      padding-right: 35px;
    }

    @media screen and (max-width: $hr-breakpoint-medium) {
      margin-bottom: 45px;

      .hr-segmented-board-title {
        @include resizeIcon(20px, 40px);
        @include resizeLabel(28px, 35px);
      }

      .hr-list .list-item {
        align-items: center;
        flex-direction: row;

        @include resizeListItemLabel(16px, 20px, 14px, 18px);
        .label {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: $hr-breakpoint-small) {
      margin-bottom: 25px;

      .hr-segmented-board-title {
        @include resizeIcon(18px, 34px);
        .icon {
          margin-right: 13px;
        }

        @include resizeLabel(28px, 35px);
      }

      .hr-segmented-board-subtitle {
        .summary {
          padding: 11px 20px 16px;
          max-width: 395px;

          .spotlight {
            margin-right: 10px;
          }
        }
      }

      .hr-list .list-item {
        @include resizeListItemLabel(16px, 20px, 14px, 18px);
      }
    }

    @media screen and (max-width: $hr-breakpoint-tablet) {
      .hr-segmented-board-subtitle {
        .summary {
          width: auto;
        }
      }
    }
  }

  @media screen and (max-width: $hr-breakpoint-medium) {
    margin-bottom: 41px;
  }
  @media screen and (max-width: $hr-breakpoint-small) {
    padding: 25px;
    width: calc(50% - 24px);
    margin: 0 12px 25px;

    &:last-of-type {
      & > .hr-segmented-board-container .hr-list .list-item {
        flex-direction: row;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: $hr-breakpoint-tablet) {
    width: calc(100% - 26px);
    padding: 30px;
    margin: 0 13px 30px;
  }
}

@import 'src/styles/variables.scss';

.hr-planilha-container {
  display: flex;
  height: 550px;
  padding: 30px 20px 40px 30px;
  .scroll {
    overflow-y: scroll;
    overflow-x: scroll;
    min-width: 100%;
    padding-right: 30px;
    // .group-block {
    //   display: flex;
    // }
  }
  ::ng-deep {
    .mat-expansion-panel-body {
      padding: 0 !important;
      margin-top: 14px;
    }
  }
  mat-accordion {
    mat-expansion-panel {
      overflow: visible;
      box-shadow: none;
      &:hover {
        background: transparent !important;
      }
      ::ng-deep {
        .mat-expansion-panel-content {
          box-shadow: none;
        }
        .mat-expansion-panel-header {
          margin: 0;
        }
        .mat-expansion-panel-header.mat-expanded {
          border: none;
        }
      }
      .groups-container {
        border: 1px solid $hr-medium-gray !important;
        border-radius: 5px !important;
        .groups-list {
          .columns-group {
            display: flex;
            justify-content: left;
            align-items: center;
            min-width: 100%;
            height: 42px;
            margin-bottom: 10px;
            padding-left: 236px;
            background: $hr-ice;
            border-radius: 5px 5px 0px 0px;
            border-bottom: 1px solid $hr-medium-gray;
            .columns-adicionais {
              display: flex;
            }
            .column {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 200px;
              max-width: 200px;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

*,
*::before,
*::after {
  font-family: 'Circular';
}

body {
  font-family: 'Circular';
  font-weight: normal;
  font-style: normal;
  color: $hr-text-color;
}

a {
  text-decoration: none;
  font-size: 1.1 * 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: $hr-text-color;
}

.hr-text-selection-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  display: flex;
  align-items: center;
  margin: 50px 0 40px 35px;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: -0.015em;
  color: $hr-text-color;

  &.hr-actions-title {
    padding-right: 180px;
    word-break: break-word;
  }

  
  @media screen and (max-width: $hr-breakpoint-medium) {
    font-size: 30px;
    line-height: 38px;
    margin: 40px 0 35px 30px;
    letter-spacing: -0.015em;

    &::before {
      width: 17px;
      height: 34px;
      left: -30px;
    }
  }

  @media screen and (max-width: $hr-breakpoint-small) {
    font-size: 28px;
    line-height: 35px;
    margin: 42px 0 38px 30px;
  }
}

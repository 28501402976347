@import 'src/styles/variables';

.stepper-container {
  background: $hr-white;
  border: $hr-border;
  box-sizing: border-box;
  box-shadow: $hr-shadow-z0;
  border-radius: $hr-border-radius;
  margin: 0px 36px 55px;

  @media screen and (max-width: $hr-breakpoint-medium) { 
    margin: 0 30px 34px;
  }

  @media screen and (max-width: $hr-breakpoint-small) {
    margin: 0 30px 50px;
  }

  .stepper-header {
    display: flex;
    justify-content: space-between;
    border-bottom: $hr-border;

    .step-title {
      padding: 26px 30px;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.015em;
      }

      .index {
        margin-right: 13px;
      }
    }

    .stepper-progress {
      display: flex;
      justify-content: center;
      align-items: center;

      .step {
        $size: 28px;
        $margin: 32px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        background: $hr-white;
        border: 2px solid $hr-input-field-gray;
        border-radius: 50%;
        box-sizing: border-box;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: $hr-input-field-gray;
        margin-right: $margin;
        transition: all 250ms ease-in-out 250ms;

        &:after,
        &:before {
          cursor: default;
          pointer-events: none;
        }

        &:not(:last-child):before {
          content: '';
          width: $margin;
          position: absolute;
          height: 2px;
          background-color: $hr-input-field-gray;
          margin-right: calc(-#{$margin} + -#{$size});
        }

        &:not(:last-child):after {
          content: '';
          width: 0px;
          position: absolute;
          height: 2px;
          background-color: $hr-gray;
          margin-right: -$size;
          transition: all 250ms ease-in-out;
          transform-origin: left;
        }

        &.current {
          box-shadow: 0px 0px 0px 6px rgba($hr-gray, 0.2);
          background-color: $hr-gray;
          color: $hr-white;
          border-color: $hr-gray;
        }

        &.completed {
          background-color: rgba($hr-gray, 0.1);
          border-color: $hr-gray;
          color: $hr-gray;

          &:not(:last-child):after {
            width: $margin;
            margin-right: calc(-#{$margin} + -#{$size});
          }
        }
      }

      .line {
        position: absolute;
        width: 100%;
        height: 2px;
        color: $hr-input-field-gray;
      }
    }

    @media screen and (max-width: $hr-breakpoint-medium) { 
      .step-title {
        padding: 28px 30px;

        span {
          font-size: 20px;
          line-height: 25px;
        }
        .index {
          margin-right: 12px;
        }
      }
    }
  
    @media screen and (max-width: $hr-breakpoint-small) {
      .step-title {
        padding: 28px 30px;
      }
    }
  }

  .stepper-content {
    padding: 40px 30px 60px;

    @media screen and (max-width: $hr-breakpoint-small) {
      padding: 40px 0 0px;
    }
  }

  .stepper-footer {
    display: flex;
    justify-content: space-between;
    border-top: $hr-border;
    padding: 30px 30px 45px;

    button {
      min-width: 168px;
      max-width: 168px;

      &:last-of-type {
        margin-left: auto;
      }
    }

    @media screen and (max-width: $hr-breakpoint-medium) { 
      padding: 30px 30px 40px;
    }
    @media screen and (max-width: $hr-breakpoint-small) {
      padding: 30px 30px 45px;
    }
  }
}

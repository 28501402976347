@import '../../variables.scss';

.hr-group-list-accordion {
  margin-bottom: 40px !important;
  mat-accordion {
    &:first-child {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
    & mat-expansion-panel:not(.mat-expanded) ~ .div-associar-grupos {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
    &:last-child {
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
    mat-expansion-panel {
      margin: 0px !important;
      ::ng-deep {
        box-shadow: none !important;
        border-radius: 5px !important;
        background-color: transparent !important;
        .mat-expansion-panel-header {
          margin: 0 0 14px 0 !important;
          flex-direction: row-reverse;
          &.mat-expanded {
            border-bottom: none !important;
            margin-bottom: 10px !important;
          }
        }
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            padding: 0 !important;
          }
        }
      }
      mat-expansion-panel-header {
        display: flex !important;
        align-items: center !important;
        padding: 20px 20px 20px 16px !important;
        height: 76px !important;
        margin-top: 40px !important;
        background: #ffebe5 !important;
        box-shadow: 0px 10px 20px rgba(237, 243, 255, 0.1) !important;
        border-radius: 5px !important;
        transition: all 250ms ease-in-out !important;
        &:first-of-type {
          margin-top: 0px !important;
        }
        &:hover,
        &:active {
          background: #ffdcd1 !important;
        }
        ::ng-deep {
          margin: 0;
          .mat-expansion-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer !important;
            margin-right: 10px;
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            border-radius: 50px;
            background: rgba(255, 255, 255, 0.5);
            position: inherit;
            z-index: 1;
          }
          .mat-expansion-indicator::after {
            color: $hr-text-color;
            padding: 4px;
          }
        }
        mat-panel-title {
          .name-categoria {
            h2 {
              font-size: 28px !important;
              line-height: 35px !important;
              letter-spacing: -0.015em !important;
            }
          }
        }
      }
      .groups-container {
        display: flex;
        flex-direction: column;
        background-color: $hr-white;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: $hr-medium-gray;
        box-sizing: border-box;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .none-groups-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .none-group {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 20px;
            width: 100%;
            height: 160px;
            background-color: #f5f6f7;
          }
          span {
            width: 180px;
            text-align: center;
            margin-bottom: 10px;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.015em;
          }
        }
      }
      .border-groups-container {
        border-width: 1px 1px 1px 1px;
        border-radius: 5px;
      }
    }
    .div-associar-grupos {
      margin-top: -4px;
      margin-bottom: 40px;
      padding: 20px 16px;
      border: 1px solid $hr-medium-gray;
      background: $hr-white;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .btn-associar-grupos {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 238px;
    height: 50px;
    background-color: $hr-white;
    border: 1px solid $hr-gray;
    box-sizing: border-box;
    border-radius: 5px;
    color: $hr-gray;
    transition: all 250ms ease-in-out;
    mat-icon {
      color: $hr-gray;
      margin-right: 16px;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #76818e;
    }
    &:hover {
      background-color: rgba(118, 129, 142, 0.15);
    }
  }
}

@import "../variables.scss";

.hr-form-field {
  margin: 32px 0;
  position: relative;
  display: flex;
  align-items: center;

  .hr-label {
    position: absolute;

    font-size: 1.1 * 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: var(--themeColor);
    margin-left: 14px;
    top: 2px;
  }

  .hr-input {
    height: 50px;
    border: none;
    width: 100%;

    font-size: 1.1 * 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    border-radius: 5px;
    box-shadow: $hr-shadow-z0;
    background-color: $hr-white;
    padding: 4px 0 0 14px;
    outline: none;
  }

  .hr-input-co {
    height: 50px;
    // border: none;
    width: 100%;

    font-size: 1.1 * 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    // border-radius: 5px;
    // box-shadow: $hr-shadow-z0;
    // background-color: $hr-white;
    padding: 4px 0 0 14px;
    outline: none;

    background: #ffffff;
    /* linhas e contorno escuro */

    border: 1px solid #cacfd1;
    box-sizing: border-box;
    /* painel sombra */

    box-shadow: 0px 1px 4px rgba(21, 27, 38, 0.08);
    border-radius: 5px;
  }

  .hr-hint-error {
    position: absolute;

    font-size: 1.1 * 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    margin-left: 14px;
    bottom: 0px;
    color: $hr-white;
    opacity: 0;
    transition: all 0.6s;
  }

  .hr-hint-error-icon {
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    right: 13px;
    opacity: 0;
    transition: all 0.3s;

    ::ng-deep svg {
      fill: $hr-pink;
    }
  }

  &.has-error .hr-hint-error-icon {
    right: 15px;
    opacity: 1;
  }

  &.has-error .hr-hint-error {
    opacity: 1;
    bottom: -16px;
  }

  &.has-error .hr-input {
    border: 2px solid $hr-pink;
  }
}

.hr-error-label {
  font-size: 1.1 * 10px;
  font-weight: 500;
  color: $hr-pink;
  display: flex;
  position: absolute;
  margin-top: 7px;

  .mat-icon {
    color: $hr-pink;
    font-size: 1.1 * 14px;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    margin-top: -2px;
  }
}

/** MATERIAL **/

.mat-form-field {
  width: 100%;
  position: relative;

  &.hr-form-btn-right {
    width: calc(100% - 45px);

    .hr-btn-round {
      position: absolute;
      top: -5px;
      right: -62px;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin-bottom: 30px;

    .mat-form-field-flex {
      padding: 18px 14px;
      background: $hr-white;
      box-shadow: $hr-shadow-z0;
      border-radius: 5px;
    }
  }

  &.no-margin .mat-form-field-wrapper {
    margin-bottom: 0;
  }

  .mat-form-field-label,
  .mat-select-placeholder {
    font-weight: 500;
    color: $hr-text-color;
  }

  .mat-form-field-label-wrapper {
    top: 0;
    padding-top: 0;
    overflow: visible;
  }

  .mat-form-field-infix {
    padding: 0;
    border-top: 0;
    font-weight: normal;
    padding-top: 2px;

    .mat-input-element,
    .mat-select-value-text {
      color: $hr-text-color;
    }

    .mat-input-element {
      padding-right: 22px;
    }
  }

  .mat-icon.mat-primary {
    color: var(--themeColor);
  }

  .mat-form-field-suffix {
    position: absolute;
    right: 14px;
    top: 15px;
    display: flex;
    align-items: center;

    svg {
      fill: var(--themeColor) !important;
    }
  }

  .mat-form-field-prefix {
    position: absolute;
    left: 10px;
    margin-top: -4px;

    & ~ .mat-form-field-infix {
      margin-left: 30px;
    }

    svg {
      fill: var(--themeColor);
    }
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
    margin: 0;
    top: 0;
    overflow: visible;
    position: relative;

    div {
      position: absolute;
      bottom: -15px;
    }
  }

  .mat-select-arrow-wrapper .mat-select-arrow {
    width: 12px;
    height: 8px;
    background-image: url("/assets/icons/chevron-down-steel.png");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
  }

  .mat-select.mat-select-invalid .mat-select-arrow-wrapper {
    position: relative;
    left: -28px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-0.9em) scale(0.75) !important;
  }

  .mat-form-field-label {
    top: 0px;
    margin-top: 0px;
  }

  .mat-form-field-ripplem,
  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    color: var(--themeColor);
  }

  &.mat-form-field-invalid {
    .mat-form-field-label {
      width: calc(100% - 45px);
    }

    .mat-error {
      color: $hr-pink;
      font-size: 1.1 * 10px;
      font-weight: 500;
      position: relative;
      top: 3px;
      text-align: left;
      justify-content: center;
      display: flex;
      align-items: center;

      &.hr-two-lines {
        top: 9px;
      }

      .mat-icon {
        color: $hr-pink;
        font-size: 1.1 * 14px;
        margin-right: 3px;
      }
    }

    .mat-form-field-flex {
      border: 2px solid var(--themeColor);
      background: $hr-white;
    }

    .mat-select .mat-select-trigger {
      width: 100%;
      display: flex;

      .mat-select-value {
        // width: calc(100% - 28px);
        max-width: none;
        white-space: normal;
      }
    }
  }

  &.hr-white-error .mat-error {
    color: $hr-white;

    .mat-icon {
      color: $hr-white;
    }
  }

  &.hr-flat {
    .mat-form-field-wrapper {
      margin-bottom: 0;
    }

    .mat-form-field-flex {
      padding: 0;
      background: none;
      border: none;
      box-shadow: none;
    }

    .mat-select-placeholder {
      font-weight: bold;
    }

    .mat-select-value-text {
      font-size: 1.1 * 14px;
      font-weight: bold;
      color: var(--themeColor);
    }

    .mat-select-arrow-wrapper .mat-select-arrow {
      width: 12px;
      height: 8px;
      background-image: url("/assets/icons/chevron-down-warm-pink.png");
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      box-shadow: $shadow-z1-disabled;
    }

    .mat-form-field-label,
    .mat-input-element,
    .mat-select-value-text {
      color: rgba($hr-gray, 0.6) !important;
    }

    .mat-select-arrow {
      opacity: 0.6 !important;
    }

    .mat-form-field-suffix svg {
      fill: rgba($hr-gray, 0.6);
    }
  }

  &.mat-focused .mat-form-field-label {
    color: $hr-text-color;
  }
}

.mat-placeholder-required {
  display: none;
}

.mat-select-search-inner {
  width: 100% !important;
}

mat-option.mat-option {
  height: unset !important;

  .mat-option-text {
    white-space: normal;
    line-height: 1.5em;
    padding: 10px;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

/** MATERIAL **/

.hr-btn {
  background: transparent;
  text-align: center;
  border: 2px solid $hr-white;
  border-radius: 5px;

  font-size: 1.1 * 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: $hr-white;
  letter-spacing: 0.8px;
  cursor: pointer;
  padding: 15px 44px 14px 44px;
  outline: none;
  position: relative;

  .mat-progress-spinner {
    position: absolute;
    top: 11px;
    left: 15px;
  }

  &.hr-btn-icon {
    padding: 15px 44px 14px 82px;

    svg-icon {
      position: absolute;
      margin-left: -38px;
    }

    mat-icon {
      position: absolute;
      margin-left: -38px;
    }

    svg {
      fill: $hr-white;
    }
  }

  &:disabled {
    cursor: default;
  }

  &.hr-btn-primary {
    box-shadow: $hr-shadow-z0;
    background-color: var(--themeColor);
    transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
    border: none;
    width: 250px;
    height: 54px;

    &:hover {
      // background-color: $warm-pink-shade;
      background-color: var(--primary-dark);
      transition: background-color 0.25s ease-in-out;
      @media screen and (max-width: $hr-breakpoint-tablet) {
        background-color: var(--primary);
      }
    }

    &:disabled {
      background-color: var(--themeTintColor);
      transition: background-color 0.25s ease-in-out;
    }
  }

  &.hr-btn-primary-outline {
    box-shadow: $hr-shadow-z0;
    border: 2px solid var(--themeColor);
    background: transparent;
    padding: 13px 44px 12px;
    color: var(--themeColor);
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
      border 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out,
      color 0.25s ease-in-out, border 0.25s ease-in-out;
    &:hover {
      background-color: var(--themeColor);
      color: $warm-pink-contrast;
      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out,
        color 0.25s ease-in-out;
      @media screen and (max-width: $hr-breakpoint-tablet) {
        background-color: transparent;
      }
    }

    &:disabled {
      background-color: var(--themeTintColor);
      border: 2px solid var(--themeTintColor);
      color: $warm-pink-contrast;
      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
        border 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out,
        color 0.25s ease-in-out, border 0.25s ease-in-out;
    }
  }

  &.hr-btn-outline {
    box-shadow: $hr-shadow-z0;
    border: 2px solid $hr-gray;
    background: transparent;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
      border 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out,
      color 0.25s ease-in-out, border 0.25s ease-in-out;
    &:hover {
      background-color: $hr-gray;
      color: $steel-contrast;
      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out,
        color 0.25s ease-in-out;
      @media screen and (max-width: $hr-breakpoint-tablet) {
        background-color: transparent;
      }
    }

    &:disabled {
      background-color: $steel-tint;
      border: 2px solid $steel-tint;
      color: $steel-contrast;
      transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
        border 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out,
        color 0.25s ease-in-out, border 0.25s ease-in-out;
    }
  }

  &.hr-btn-aux {
    border-radius: 5px;
    box-shadow: $hr-shadow-z0;
    background-color: $hr-white;
    border: none;
    padding: 12px 23px;
    color: $hr-text-color;
    font-size: 1.1 * 12px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    padding-left: 42px;

    &:active {
      box-shadow: inset 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    }

    &:focus {
      outline: none;
    }

    ::ng-deep svg {
      width: 18.5px;
      position: absolute;
      top: 8px;
      margin-left: -20px;
      fill: $hr-text-color;
    }

    &.hr-icon-suffix {
      padding-left: 24px;
      padding-right: 42px;

      ::ng-deep svg {
        right: 0;
        margin: 2px 20px 0 0;
      }
    }

    &.hr-dark {
      background: $hr-gray;
      color: $hr-white;

      ::ng-deep svg {
        fill: $hr-white;
      }
    }

    &.hr-btn-flat {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.hr-btn-round {
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border: none;
  outline: none;
  box-shadow: $hr-shadow-z0;
  background-color: $hr-white;
  transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;

  svg-icon {
    height: 24px;
  }

  svg {
    fill: $hr-white;
    width: 24px;
    height: 24px;
  }

  &.hr-btn-flat {
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: #eaebed;
      transition: background-color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out;
    }
  }

  &.hr-btn-label {
    position: relative;
    label {
      position: absolute;
      left: 40px;
      transition: color 0.25s ease-in-out;
      -webkit-transition: color 0.25s ease-in-out;
    }

    &:disabled {
      label {
        color: #eaebed;
        transition: color 0.25s ease-in-out;
        -webkit-transition: color 0.25s ease-in-out;
      }
    }
  }

  &.hr-btn-primary {
    background-color: var(--themeColor);
    transition: background-color 0.25s ease-in-out;

    &:hover {
      // background-color: $warm-pink-shade;
      background-color: var(--primary-dark);
      transition: background-color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out;
    }
  }

  &:disabled {
    background-color: var(--themeTintColor);
    transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
  }
}

.hr-pagination {
  .hr-btn-round {
    width: 40px;
    height: 40px;
    transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;

    &.left {
      transform: rotate(180deg);
      margin-right: 20px;
    }

    &:disabled {
      background-color: #eaebed;
      cursor: default;
      transition: background-color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out;
    }
  }

  label {
    font-size: 1.1 * 16px;
    font-weight: 500;
    color: var(--themeColor);
    margin-left: 10px;
    cursor: pointer;
  }
}

.tooltip {
  padding: 4px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $hr-dark-gray;
  color: $hr-white;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  display: inline-table;
  font-size: 1.1 * 10px;
  font-weight: 500;
  letter-spacing: normal;
  white-space: nowrap;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::before {
  content: " ";
  position: absolute;
  bottom: 93%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $hr-dark-gray transparent;
}

.hr-path {
  display: flex;
  margin-top: 28px;

  div {
    margin-right: 10px;
    font-size: 1.1 * 12px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.15;

    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background: $hr-gray;
      border-radius: 999px;
      position: relative;
      margin-bottom: 7px;
    }

    &.active {
      opacity: 1;
      color: var(--themeColor);

      &:before {
        background: var(--themeColor);
      }
    }
  }
}

.mat-slide-toggle {
  font-size: 1.1 * 10px;
  font-weight: normal;
  line-height: 1.3;

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--themeColor);

      .mat-slide-toggle-thumb-container {
        transform: translate3d(18px, 0, 0);
      }
    }
    .mat-slide-toggle-thumb {
      background-color: $hr-white;
    }
  }
  .mat-slide-toggle-bar {
    background-color: $hr-gray;
    margin-left: 10px;
    width: 39px;
    height: 21px;
    border-radius: 11px;

    .mat-slide-toggle-thumb-container {
      width: 15px;
      height: 21px;
      top: 3px;
      left: 3px;

      .mat-slide-toggle-bar,
      .mat-slide-toggle-thumb {
        width: 15px;
        height: 15px;
      }
      .mas-slide-toggle-ripple {
        top: calc(50% - 23px);
      }
    }
  }
}

.hr-double-line-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.hr-radio-group {
  .hr-texto-question {
    font-weight: 500;
  }

  .hr-radio-button {
    display: flex;
    width: 43%;
    padding: 10px 0;
  }

  mat-radio-button {
    padding-right: 40px;
  }
}

.mat-radio-button {
  &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--themeColor);
  }

  &.mat-accent .mat-radio-inner-circle {
    background-color: var(--themeColor);
  }
}

.error-area {
  mat-error {
    position: unset;
    color: $hr-pink;
    font-size: 1.1 * 12px;
    font-weight: 500;
    top: 3px;
    text-align: left;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: rgba(118, 129, 142, 0.1);
    height: 40px;
    width: 335px;

    mat-icon {
      color: $hr-pink !important;
      font-size: 1.1 * 14px;
      margin-right: 3px;
      height: 14px;
      width: 14px;
    }
  }

  &.error-full {
    margin-top: 10px;

    mat-error {
      width: 100%;
    }
  }

  .blank-space {
    width: 100%;
    height: 40px;
  }
}

.hr-image-input {
  position: relative;
  margin-bottom: 8px;
}
.hr-image-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .mat-error {
    position: unset;
    color: $hr-pink;
    font-size: 1.1 * 12px;
    font-weight: 500;
    top: 3px;
    text-align: left;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $light-steel;
    height: 50px;

    .mat-icon {
      color: $hr-pink;
      font-size: 1.1 * 14px;
      margin-right: 3px;
      height: 14px;
      width: 14px;
    }
  }
}

textarea.mat-input-element {
  resize: none !important;
}

.loader-area {
  padding: 12px 56px 11px 56px;

  .loader {
    font-size: 1.1 * 3px;
    position: relative;
    border-top: 0.9em solid rgba(255, 255, 255, 0.2);
    border-right: 0.9em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.9em solid rgba(255, 255, 255, 0.2);
    border-left: 0.9em solid $hr-white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

.loader-area-empresas {
  padding: 38px 56px 11px 56px;
  height: 400px;
  top: 40px;
  ::ng-deep.mat-option-text {
    display: flex;
    justify-content: center;
  }

  .loader {
    font-size: 1.1 * 3px;
    position: relative;
    border-top: 0.9em solid rgba(255, 90, 122, 0.2);
    border-right: 0.9em solid rgba(255, 90, 122, 0.2);
    border-bottom: 0.9em solid rgba(255, 90, 122, 0.2);
    border-left: 0.9em solid var(--themeColor);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1s infinite linear;
    animation: load8 1s infinite linear;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
}

.hr-general-error-area {
  position: unset;
  color: $hr-pink;
  font-size: 1.1 * 12px;
  font-weight: 500;
  top: 3px;
  text-align: left;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgba(118, 129, 142, 0.1);
  height: 40px;

  .mat-icon {
    color: $hr-pink !important;
    font-size: 1.1 * 14px;
    margin-right: 3px;
    height: 14px;
    width: 14px;
  }
}

.mat-slide-toggle-content {
  font-family: "Circular";
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: $hr-pink;
}

@import '../variables.scss';

.hr-top-tools {
  display: flex;
  margin-bottom: 15px;
  margin-left: 8px;
  margin-right: 8px;

  .hr-top-left {
    flex-grow: 1;
  }

  .hr-top-right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
  stroke: $hr-pink;
}

.hr-snackbar {
  background-color: $hr-white;
  color: $hr-text-color;
  font-size: 1.1 * 14px;
  font-weight: 500;
  border-radius: 10px;
  border-left: 7px solid #09dbcc;
  box-shadow: 0 15px 20px 0 rgba(255, 90, 122, 0.25);

  &.mat-snack-bar-container {
    min-width: unset;
  }

  simple-snack-bar {
    max-width: 320px;
  }
}

.hr-erro-snackbar {
  background-color: $hr-white;
  color: $hr-text-color;
  font-size: 1.1 * 14px;
  font-weight: 500;
  border-radius: 10px;
  border-left: 7px solid $warm-pink-shade;
  box-shadow: 0 15px 20px 0 rgba(255, 90, 122, 0.25);

  &.mat-snack-bar-container {
    min-width: unset;
  }
}

.hr-snackbar-translucido {
  background-color: $hr-white;
  color: $hr-text-color;
  font-size: 1.1 * 14px;
  font-weight: 500;
  border-radius: 10px;
  border-left: 7px solid #09dbcc;
  box-shadow: 0 15px 20px 0 rgba(255, 90, 122, 0.25);
  opacity: 0.75 !important;

  &.mat-snack-bar-container {
    min-width: unset;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  font-size: 1.1 * 10px;
  position: relative;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid $hr-white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-loader {
  font-size: 3px;
  position: relative;
  border-top: 1em solid rgba(255, 255, 255, 0.2);
  border-right: 1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1em solid rgba(255, 255, 255, 0.2);
  border-left: 1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1s infinite linear;
  animation: load8 1s infinite linear;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  margin: -2px auto;
}
.btn-loader,
.btn-loader:after {
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.scroll-area {
  width: 100%;
  position: relative;

  drag-scroll {
    width: 100%;
    overflow: visible;

    .drag-scroll-content {
      display: flex !important;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  ::ng-deep {
    .drag-scroll-content {
      padding-bottom: 50px;
    }
    hr-timeline-section:first-of-type,
    hr-card-candidato:first-of-type {
      .hr-card-candidato {
        margin-left: 15px;
      }
    }
  }
}

.ds-pagination {
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-flex;

  &.ds-right {
    right: 30px;
  }

  &.ds-left {
    left: 30px;
  }

  .hr-btn-round {
    margin: auto;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .hr-btn-round.left {
    transform: rotate(180deg);
  }
}

.no-padding {
  padding: 0 !important;
}

.hr-top-tools {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .hr-top-left {
    border-radius: 5px;
    background-color: $hr-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    flex-grow: unset;

    mat-icon {
      margin-right: 6px;
      margin-left: 6px;
    }

    input {
      width: 600px;
      height: 50px;
      border-radius: 5px;
      background-color: $hr-white;
      border: none;

      font-size: 1.1 * 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: left;
    }

    @media screen and (max-width: 900px) {
      input {
        width: 410px;
      }
      .hr-top-right button {
        padding: 15px 30px 15px 70px;
      }
    }

    @media screen and (max-width: 700px) {
      input {
        width: 300px;
      }
      .hr-top-right button {
        padding: 15px 15px 15px 55px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .hr-top-right button {
      padding: 15px 30px 15px 70px;
    }
  }
  @media screen and (max-width: 700px) {
    .hr-top-right button {
      padding: 15px 15px 15px 55px;
    }
  }
}

.hr-columns-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .hr-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hr-column-border {
    border-right: 1px solid $border-default;
    padding-right: 20px;
    width: 50%;
    margin-right: 20px;
  }
}

.hr-inactive-label {
  color: #eaebed !important;
  cursor: default !important;
  transition: color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out;
}

.hr-button-second {
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
  background-color: $hr-white;
  border: none;
  margin-left: 15px;
  padding: 12px 23px;

  font-size: 1.1 * 12px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(55, 63, 81, 0.15);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: $hr-pink;
    mat-icon {
      fill: $hr-pink;
    }
  }

  mat-icon {
    margin-right: 10px;
    font-size: 1.1 * 20px;
  }

  &.hr-dark {
    background: $hr-gray;
    color: $hr-white;

    mat-icon {
      fill: $hr-white;
    }
  }
}

.hr-download-user-image {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: $hr-gray;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg-icon {
    fill: $hr-white;
  }
}

.hr-header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  margin-right: 30px;
  .hr-border-divisor {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 15px;
  }
  .hr-span-order {
    width: 84px;

    font-size: 1.1 * 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
  }
  .arrow-down {
    width: 12px;
    height: 8px;
    margin-left: 10px;
    background-image: url('/assets/icons/chevron-down-steel.png');
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
  }

  .hr-ordena-lista {
    position: relative;
    top: 20px;
    right: 10px;
    width: 232px;
    div {
      height: 40px;
      box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
      background-color: $hr-white;
      border: none;
      padding: 12px 23px;

      font-size: 1.1 * 12px;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $hr-pink;
      }
      p {
        margin: 0;
        text-align: end;
        padding-right: 10px;
      }
    }
    div {
      &:last-of-type {
        p:first-child {
          border-bottom: none;
        }
      }
      p:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0;
        padding-bottom: 11px;
      }
      p {
        text-align: end;
        padding-right: 10px;
      }
    }
  }

  .hr-type-ordenacao {
    &:first {
      margin-left: 0px;
    }
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    background-color: $hr-white;
    border: none;
    // padding: 12px 6px;

    font-size: 1.1 * 12px;
    font-weight: 500;
    cursor: pointer;
    width: 40px;
    margin-left: 15px;
    height: 40px;
    @media screen and (max-width: 1155px) {
      margin-left: 6px;
      // padding: 12px 18px;
    }
    &:active {
      box-shadow: inset 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    }
    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
      border: none;
      cursor: pointer;
      color: $hr-pink;
      .arrow-down {
        background-image: url('/assets/icons/chevron-down-warm-pink.png');
      }
      .hr-hint {
        visibility: visible;
      }
      svg-icon {
        fill: $hr-pink;
      }
    }
    .hr-hint {
      position: absolute;
      color: $hr-white;
      background: $hr-dark-gray;
      box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);

      font-size: 1.1 * 10px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: normal;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.15em;
      visibility: hidden;
      border-radius: 6px;
      padding: 0 15px;
      z-index: 999;
      top: 110%;
    }
    .arrow-down {
      width: 12px;
      height: 8px;
      margin-left: 10px;
      background-image: url('/assets/icons/chevron-down-steel.png');
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      transform: rotate(360deg);
    }
    .arrow-up {
      transform: rotate(180deg);
    }
    svg-icon {
      width: 20px;
      height: 20px;
      // margin-left: -5px;
      fill: $hr-text-color;
      // margin-right: 6px;
      margin-bottom: -6px;
      margin-top: -4px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    mat-icon {
      // margin-right: 10px;
      margin-bottom: -4px;
      margin-top: -4px;
    }
  }

  .hr-header-option-filter {
    &:first {
      margin-left: 0px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    background-color: $hr-white;
    border: none;
    padding: 12px 23px;

    font-size: 1.1 * 12px;
    font-weight: 500;
    cursor: pointer;
    width: 160px;
    margin-left: 15px;
    height: 40px;
    @media screen and (max-width: 1155px) {
      margin-left: 6px;
      padding: 12px 18px;
    }
    &:active {
      box-shadow: inset 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      color: $hr-pink;
      ::ng-deep svg {
        fill: $hr-pink;
        width: 18.5px;
        margin-left: -5px;
        margin-right: 6px;
        margin-bottom: -6px;
        margin-top: -4px;
      }
      mat-icon {
        margin-right: 10px;
        margin-bottom: -4px;
        margin-top: -4px;
      }
      @media screen and (max-width: 1155px) {
        margin-left: 6px;
        padding: 12px 18px;
      }
      .arrow-down {
        width: 12px;
        height: 8px;
        margin-left: 10px;
        background-image: url('/assets/icons/chevron-down-warm-pink.png');
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
      }
    }
    ::ng-deep svg {
      width: 18.5px;
      margin-left: -5px;
      fill: $hr-text-color;
      margin-right: 6px;
      margin-bottom: -6px;
      margin-top: -4px;
    }
    mat-icon {
      margin-right: 10px;
      margin-bottom: -4px;
      margin-top: -4px;
    }
  }

  .hr-ordenacao-button {
    &:first {
      margin-left: 0px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    background-color: $hr-white;
    border: none;
    padding: 12px 23px;

    font-size: 1.1 * 12px;
    font-weight: 500;
    cursor: pointer;
    width: 160px;
    margin-left: 15px;
    height: 40px;
    @media screen and (max-width: 1155px) {
      margin-left: 6px;
      padding: 12px 18px;
    }
    &:active {
      box-shadow: inset 0 1px 3px 0 rgba(55, 63, 81, 0.15);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      color: $hr-pink;
      ::ng-deep svg {
        fill: $hr-pink;
        width: 18.5px;
        margin-left: -5px;
        margin-right: 6px;
        margin-bottom: -6px;
        margin-top: -4px;
      }
      mat-icon {
        margin-right: 10px;
        margin-bottom: -4px;
        margin-top: -4px;
      }
      @media screen and (max-width: 1155px) {
        margin-left: 6px;
        padding: 12px 18px;
      }
      .arrow-down {
        width: 12px;
        height: 8px;
        margin-left: 10px;
        background-image: url('/assets/icons/chevron-down-warm-pink.png');
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
      }
    }
    ::ng-deep svg {
      width: 18.5px;
      margin-left: -5px;
      fill: $hr-text-color;
      margin-right: 6px;
      margin-bottom: -6px;
      margin-top: -4px;
    }
    mat-icon {
      margin-right: 10px;
      margin-bottom: -4px;
      margin-top: -4px;
    }
  }

  .hr-ordenacao-button-carta-oferta {
    border: 1px solid $hr-light-gray;
    box-shadow: 0px 1px 3px $hr-onboarding-gray;
  }

  .hr-filtro-aplicado {
    color: $hr-pink;
    mat-icon {
      color: $hr-pink;
    }
    svg-icon {
      fill: $hr-pink;
    }
  }
}

.hrestart-blue-template {
  fill: $blue-hrestart;
  color: $blue-hrestart;
}

.hrestart-blue-button {
  border: 1px solid $blue-hrestart;
  background-color: $blue-hrestart;
}

.hr-skeleton-item {
  opacity: 0.15;
  overflow: hidden;
  animation: loading 1.7s infinite linear;
  background: $hr-gray;
  background-image: -webkit-linear-gradient(to left, $hr-gray 0%, $steel-tint 20%, $hr-gray 40%, $hr-gray 100%);
  background-image: linear-gradient(to left, $hr-gray 0%, $steel-tint 20%, $hr-gray 40%, $hr-gray 100%);
  background-repeat: no-repeat;
}

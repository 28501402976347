.mat-checkbox {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
  transition: all 0.25s ease-in-out;

  .mat-checkbox-mixedmark {
    background-color: transparent;
  }

  .mat-checkbox-frame {
    border: none;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    white-space: normal;
  }

  .mat-checkbox-background,
  .mat-checkbox-inner-container {
    background-color: $hr-white;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    border-radius: $hr-border-radius;
  }

  .mat-checkbox-background {
    border: $hr-border;
    box-shadow: $hr-shadow-z0;
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--themeColor) !important;

    .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
      stroke: $hr-white !important;
    }
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-inner-container {
      opacity: 0.3;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $hr-white;
    }

    .mat-checkbox-label {
      color: inherit;
    }
  }

  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--themeColor) !important;
  }
}


.hr-svg-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.hr-icon.hr-tooltip-trigger {
  justify-content: center;
  align-items: center;
  max-width: 25px;
  max-height: 25px;
  margin-left: 14px;
  border-radius: 50%;
  background-color: $hr-onboarding-gray;
  padding: 5px;
  cursor: pointer;
  z-index: 10;

  svg-icon ::ng-deep svg {
    fill: $hr-text-color;
    transition: fill 200ms ease-in-out;
  }

  &:hover {
    svg-icon ::ng-deep svg {
      fill: $hr-pink;
    }
  }
}

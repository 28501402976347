@import 'src/styles/components/percentage-circle';

@mixin resizeIcon($icon-padding, $icon-width) {
  .icon {
    padding: $icon-padding;
    img {
      height: $icon-width;
      width: $icon-width;
    }
  }
}

@mixin resizeLabel($label-number-font-size, $label-number-line-height, $label-description-font-size) {
  .label {
    .number {
      font-size: $label-number-font-size;
      line-height: $label-number-line-height;
    }
    .description {
      font-size: $label-description-font-size;
    }
  }
}

.hr-general-info {
  padding: 50px 35px;
  border-radius: $hr-large-border-radius;
  background-color: $hr-white;
  //border: $hr-border;
  box-sizing: border-box;
  box-shadow: $hr-shadow-z2;
}

.hr-general-info > .hr-general-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1500px;

  .hr-general-info-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include resizeIcon(15.2px, 49px);

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $hr-onboarding-gray;
      border-radius: 50%;
      margin-right: 20px;
    }

    @include resizeLabel(38px, 48px, 14px);

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .number {
        font-style: normal;
        font-weight: 500;
        color: $hr-text-color;
      }

      .description {
        font-style: normal;
        font-weight: normal;
        line-height: 155%;
        color: $hr-text-color;
      }
    }

    @media screen and (max-width: $hr-breakpoint-small) {
      @include resizeIcon(13.3px, 43px);
      @include resizeLabel(32px, 40px, 14px);
    }
    @media screen and (min-width: $hr-breakpoint-medium) {
      @include resizeIcon(20px, 60px);
      @include resizeLabel(48px, 61px, 16px);
    }
  }
}

.hr-info-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .hr-percentage-circle {
    .c100 {
      margin: 0 20px 0 0;
      background-color: $hr-onboarding-gray;
    }
  }

  @include resizePercentageCircle(80px, 12px, 16px, 20px);

  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-style: normal;
    color: $hr-text-color;
    line-height: 155%;
    font-size: 14px;

    .nivel {
      font-weight: bold;
    }

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        font-weight: normal;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 25px;
        max-height: 25px;
        margin-left: 14px;
        border-radius: 50%;
        background-color: $hr-onboarding-gray;
        padding: 5px;

        svg {
          width: 15px;
          height: 15px;
          fill: $hr-text-color !important;
        }
      }
    }
  }

  @media screen and (max-width: $hr-breakpoint-small) {
    @include resizePercentageCircle(70px, 12px, 16px, 20px);

    .label {
      font-size: 14px;
    }
  }
  @media screen and (min-width: $hr-breakpoint-medium) {
    @include resizePercentageCircle(100px, 14px, 28px, 155%);

    .label {
      font-size: 16px;
    }
  }
}

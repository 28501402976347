@import '../variables.scss';

.mat-accordion .mat-expansion-panel {
  border-radius: 10px !important;
  margin-bottom: 15px;
  box-shadow: $hr-shadow-z0;

  .mat-expansion-panel-header {
    border-radius: 0px;
    margin: 0 20px;
    padding: 0;

    .mat-expansion-panel-header-title {
      max-width: 240px;
            font-size: 1.1 * 16px;
      font-weight: 500;
    }

    &.mat-expanded {
      border-bottom: 1px solid rgba($hr-dark-gray, 0.1);

      .mat-expansion-panel-header-title {
        color: $hr-pink;
      }
    }
  }

  &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: none;
  }

  &:not(.mat-expanded):hover {
    background: #eee;
  }

  .hr-panel-content {
    margin-top: 25px;
        font-size: 1.1 * 16px;
  }
}

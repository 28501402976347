@import '../variables.scss';

.mat-tab-group {
  margin-top: 25px;
  width: 100%;

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-body-content {
    padding: 0 8px;
  }

  .mat-tab-labels {
    justify-content: center;
  }

  .mat-tab-label {
        font-size: 1.1 * 16px;
    font-weight: 500;
    opacity: 1;

    &.mat-tab-label-active {
      color: $hr-pink;
    }

    &.mat-tab-disabled {
      opacity: 0.5;
    }
  }

  .mat-tab-label:first-child {
    padding: 0;
    justify-content: flex-end;
    margin-right: 10px;

    @media screen and (min-width: 700px) {
      margin-right: 60px;
    }
  }

  .mat-tab-label:last-child {
    padding: 0;
    justify-content: flex-start;
    margin-left: 10px;

    @media screen and (min-width: 700px) {
      margin-left: 60px;
    }
  }

  .mat-ink-bar {
    height: 4px;
    background-color: $hr-pink !important;
  }
}

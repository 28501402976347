

.hr-loader-area {
  width: 100%;
  margin-bottom: 22px;
  height: 300px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loader {
    width: 70px;
    height: 70px;
    border-top: 8px solid rgba($hr-pink, 0.2);
    border-right: 8px solid rgba($hr-pink, 0.2);
    border-bottom: 8px solid rgba($hr-pink, 0.2);
    border-left: 8px solid $hr-pink;
    padding: 0;
  }

  label {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.26px;
    width: 50%;
    text-align: center;
    margin-top: 33px;
  }

}

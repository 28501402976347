.hr-list-area {
  display: flex;
  flex-direction: column;
  padding-top: 15px;

  .hr-list-item {
    width: 100%;
    height: 75px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: $hr-white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.1%;
    // padding: 19px;
    cursor: pointer;
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
      box-shadow: $shadow-list-item;
      transition: box-shadow 0.25s ease-in-out;
    }

    .hr-first-block {
      padding-left: 15px;
      width: 45%;
      height: 75px;
      display: flex;
      align-items: center;
      flex-direction: row;

      .hr-date-container .hr-span-data {
        font-weight: 500;
      }

      .hr-block-text-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 45px;
        justify-content: center;
        align-items: flex-start;
        margin-left: 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        @media screen and (min-width: 1280px) {
          margin-left: 30px;
        }

        .hr-texto-radio {
          display: flex;
          flex-direction: column;
          text-align: left;
          justify-content: center;
          margin-right: 20px;

          .hr-span-first {
            word-break: break-word;

            font-size: 1.1 * 16px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: $hr-pink;
          }
        }
      }
    }

    .hr-second-block {
      width: 25%;
      display: flex;
      align-items: center;
      flex-direction: row;

      .hr-texto-radio {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .hr-span-first {
          line-height: 1.31;
          text-align: left;

          font-size: 1.1 * 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          cursor: text;
        }
        .views-button:hover {
          cursor: pointer;
          color: $hr-pink;
        }

        span:last-of-type {
          margin-left: 20px;
          font-weight: normal;
        }
      }
    }

    .hr-third-block {
      min-width: 200px;
      max-width: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 19px 19px 19px 0px;

      @media screen and (min-width: 1000px) {
        min-width: 220px;
        max-width: 220px;
      }

      &.hr-readonly {
        justify-content: flex-end;
        padding-right: 30px;

        @media screen and (min-width: 1000px) {
          padding-right: 50px;
        }

        .hr-opcoes {
          font-weight: normal;

          &.inativo {
            padding-left: 0;
          }

          &:hover {
            svg-icon {
              fill: $hr-text-color;
            }
          }
        }
      }
    }

    .hr-autor {
      width: 35%;
      display: flex;
      align-items: center;
      flex-direction: row;
      .hr-texto-radio {
        display: flex;
        justify-content: flex-start;
        margin-left: 5%;
        width: 100%;
      }
    }
  }
}

.hr-opcoes {
  font-size: 1.1 * 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.inativo {
    padding-left: 24px;
  }

  &:hover {
    color: $hr-pink;
    svg-icon {
      fill: $hr-pink !important;
    }
  }

  svg-icon {
    width: 22px;
    fill: $hr-text-color;
    margin-left: 6px;
    align-items: center;
    display: flex;
  }
}

@mixin resizePercentageCircle(
  $circle-width,
  $circle-border-width,
  $circle-percentage-font-size,
  $circle-percentage-line-height
) {
  .hr-percentage-circle {
    .c100 {
      width: $circle-width;
      height: $circle-width;
      min-width: $circle-width;
      min-height: $circle-width;
      font-size: $circle-width;

      &:after {
        width: calc(#{$circle-width} - #{$circle-border-width}); // tamanho do circulo - largura da borda
        height: calc(#{$circle-width} - #{$circle-border-width}); // tamanho do circulo - largura da borda
      }

      .percentage {
        font-size: $circle-percentage-font-size;
        line-height: $circle-percentage-line-height;
      }
    }
  }
}

@mixin resizeListItemLabel(
  $label-title-font-size,
  $label-title-line-height,
  $label-description-font-size,
  $label-description-line-height
) {
  .label {
    .title {
      font-size: $label-title-font-size;
      line-height: $label-title-line-height;
    }
    .description {
      font-size: $label-description-font-size;
      line-height: $label-description-line-height;
    }
  }
}


.hr-list {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 0;
    border-bottom: $hr-border;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
    //height: 100px;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      .label {
        color: $hr-pink;
        transition: color 0.25s ease-in-out;
      }
    }

    @include resizeListItemLabel(16px, 20px, 14px, 18px);

    .label {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: bold;
      }

      .description {
        font-weight: normal;
      }
    }

    .last-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .hr-percentage-circle {
        .c100 {
          margin: 0 20px 0 0;
          background-color: $hr-onboarding-gray;
        }
      }

      @include resizePercentageCircle(55px, 5px, 14px, 18px);

      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-style: normal;
        color: $hr-text-color;
        line-height: 18px;
        font-size: 14px;

        .nivel {
          font-weight: bold;
        }

        & > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            font-weight: normal;
          }

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 25px;
            max-height: 25px;
            margin-left: 14px;
            border-radius: 50%;
            background-color: $hr-onboarding-gray;
            padding: 5px;

            svg {
              width: 15px;
              height: 15px;
              fill: $hr-text-color !important;
            }
          }
        }
      }

      @media screen and (max-width: $hr-breakpoint-small) {
        @include resizePercentageCircle(44px, 4px, 12px, 15px);
      }

      @media screen and (max-width: $hr-breakpoint-tablet) {
        @include resizePercentageCircle(55px, 5px, 14px, 18px);
      }
    }

    @media screen and (max-width: $hr-breakpoint-medium) {
      @include resizeListItemLabel(14px, 18px, 14px, 18px);
    }

    @media screen and (max-width: $hr-breakpoint-small) {
      flex-direction: column;
      align-items: flex-start;
      //height: 146px;

      .label {
        margin-bottom: 15px;
      }
    }

    @media screen and (max-width: $hr-breakpoint-tablet) {
      @include resizeListItemLabel(16px, 20px, 14px, 18px);
      align-items: center;
      flex-direction: row;

      .label {
        margin-bottom: 0;
      }
    }
  }

  .no-items-found {
    background: $hr-onboarding-gray;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    visibility: hidden;
    padding: 16px 0;
    width: calc(100% + 35px); // adding the padding of the scroll
    text-align: center;
    //height: 0;

    &.empty-list {
      width: 100%;
      background: transparent;
    }

    &:first-of-type {
      visibility: visible;
    }

    //&:last-of-type {
    //  visibility: visible;
    //  height: auto;
    //  padding: 16px 0;
    //}
  }
}

.hr-added-item {
  @extend .hr-default-field;
  padding: 16px;
  height: 46px !important;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 155%;
    letter-spacing: -0.015em;
  }

  @media screen and (max-width: $hr-breakpoint-medium) {
    padding: 0 14px;
  }
}

.hr-default-data {
  display: flex;
  align-items: center;

  .image-area {
    img {
      border-radius: 50%;
      max-width: 70px;
      min-width: 70px;
    }
  }

  .data-area {
    flex-grow: 1;

    &:not(:first-of-type) {
      margin-left: 15px;
    }

    .title {
      font-weight: 500;
    }
  }
}


.hr-no-items {
  display: flex;
  align-items: center;
  background: #f7f3fd;
  border-radius: $hr-border-radius;
  height: 42px;
  padding: 12px 16px;
  width: 100%;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #f7f8fa;
}

html.cdk-global-scrollblock {
  overflow: hidden;
}

a {
  cursor: pointer;
}

textarea {
  width: 100%;
  border: none;
  resize: vertical;

  &:focus {
    outline: none;
  }
}

input:focus {
  outline: none;
}

input, textarea {
  caret-color: #2b2b2b !important;
}

[hidden] {
  display: none !important;
}

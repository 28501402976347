/* COLOR */
$hr-pink: #ff4c7f;
$hr-pink-transparent: rgba(255, 76, 127, 0.1);
$hr-light-pink: #ffedf2;
$hr-purple: #46257a;
$hr-light-purple: rgba(70, 37, 122, 0.1);
$hr-very-light-pink: #fff6f6;

$hr-blue: #4032a7;
$hr-orange: #ff6840;
$hr-light-orange: rgba(255, 104, 64, 0.1);
$hr-green: #3df2d2;
$hr-light-blue: #80d8ff;
$hr-dark-gray: #373f51;
$hr-gray: #76818e;
$hr-light-gray: #e8ecee;

$hr-white: #ffffff;
$hr-ice: #f7f8fa;
$hr-light-orange: #ffebe5;
$hr-text-color: #2b2b2b;
$hr-medium-gray: #cacfd1;
$hr-text-medium-gray: #666666;
$hr-onboarding-gray: #edf3ff;
$hr-onboarding-background: #f6f4ff;
$hr-onboarding-light: #f2ebfc;
$hr-success-color: #51e072;
$hr-success-color-transparent: rgba(78, 203, 91, 0.15);
$hr-warning-color: #ffb800;
$hr-warning-color-transparent: rgba(255, 184, 0, 0.15);
$hr-fail-color: #f94d4d;
$hr-fail-color-transparent: rgba(249, 77, 77, 0.15);
$hr-blue-color: #51e0e0;
$hr-blue-color-transparent: #e5fafa;

$hr-onboarding-dropzone: $hr-onboarding-light;
$hr-error-background: #f5eeee;
$hr-error-background-list: #fff6f6;

$hr-input-field-gray: $hr-medium-gray;
$hr-input-border: 1px solid $hr-medium-gray;

/* GRADIENT */
$hr-gradient-orange-pink-blue: linear-gradient(
  89.96deg,
  $hr-orange -18.31%,
  #ff5a7a 14.18%,
  $hr-pink 66.06%,
  $hr-blue 106.45%
);
$hr-pink-purple: linear-gradient(252.47deg, $hr-pink 20%, $hr-purple 110.3%);

/* CONTAINER */
$hr-container-max-width: calc(1920px - 2 * 50px);
$hr-breakpoint-medium: 1679px;
$hr-breakpoint-small: 1365px;
$hr-breakpoint-tablet: 1024px;
$hr-breakpoint-small-tablet: 768px;
$hr-breakpoint-mobile: 450px;

$notebook-height: 750px;
$notebook-small-height: 700px;
/* SHADOW */
$hr-shadow-z0: 0px 1px 4px rgba(21, 27, 38, 0.08);
$hr-shadow-z1: 0px 15px 40px rgba(21, 27, 38, 0.12);
$hr-shadow-z2: 0px 25px 60px rgba(55, 63, 81, 0.1);

$hr-shadow-offer-card: 0px 4px 4px rgba(219, 214, 214, 0.25);

$hr-shadow-btn: 0px 1px 3px $hr-onboarding-gray;

/* BORDER */
$hr-border: 1px solid $hr-onboarding-gray;
$hr-border-2: 1px solid $hr-light-gray;
$hr-border-radius: 5px;
$hr-large-border-radius: 10px;
$hr-marked-border: 2px solid $hr-light-gray;

/* CHART */
$hr-chart-high-color: $hr-success-color;
$hr-chart-medium-color: $hr-warning-color;
$hr-chart-low-color: $hr-fail-color;

$hr-success-color: $hr-chart-high-color;
$hr-warning-color: $hr-chart-medium-color;
$hr-failure-color: $hr-chart-low-color;

/* COLOR VARIATIONS */
$hr-pink-darken: #d94c68;
$hr-gray-darken: #646e79;

$hr-warning-color-transparent: rgba(255, 184, 0, 0.1);
$hr-failure-color-transparent: rgba(249, 77, 77, 0.1);

/* BASE TRANSITION*/
$hr-transition: all 0.25s ease-in-out;

/*
PADRÕES ANTIGOS
*/
$warm-pink-contrast: #fff;
$warm-pink-shade: #df3757;
$warm-pink-tint: lighten($hr-pink, 15%);

$blue-hrestart: #76beff;

$steel-contrast: #fff;
$steel-shade: darken($hr-gray, 10%);
$steel-light: lighten($hr-gray, 15%);
$steel-tint: lighten($hr-gray, 30%);

$light-steel: rgba(118, 129, 142, 0.1);
$light-steel-opaque: #f1f2f3;

$border-default: rgba(0, 0, 0, 0.1);
$background-color: rgba(55, 63, 81, 0.8);

$charcoal-grey-contrast: #fff;
$charcoal-grey-shade: darken($hr-dark-gray, 10%);
$charcoal-grey-tint: lighten($hr-dark-gray, 30%);

$aquamarine: #09dbcc;
$aquamarine-contrast: #000;
$aquamarine-shade: darken($aquamarine, 10%);
$aquamarine-tint: lighten($aquamarine, 30%);

$marigold: #ffc400;
$marigold-contrast: #000;
$marigold-shade: darken($marigold, 3%);
$marigold-tint: lighten($marigold, 30%);

$neon-red: #f51e25;
$neon-red-contrast: #000;
$neon-red-shade: darken($neon-red, 10%);
$neon-red-tint: lighten($neon-red, 30%);

$sky-blue: #76beff;
$sky-blue-contrast: #000;
$sky-blue-shade: darken($sky-blue, 10%);
$sky-blue-tint: lighten($sky-blue, 30%);

$pumpkin-orange: #ff8800;
$pumpkin-orange-contrast: #000;
$pumpkin-orange-shade: darken($pumpkin-orange, 10%);
$pumpkin-orange-tint: lighten($pumpkin-orange, 30%);

/** Shadow **/
$shadow-z1: 0 1px 3px 0 rgba(55, 63, 81, 0.15);
$shadow-z1-disabled: 0 1px 3px 0 rgba(55, 63, 81, 0.1);
$shadow-z5: 0 15px 20px 0 rgba(55, 63, 81, 0.1);

$shadow-list-item: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
$shadow-button-icon: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
$shadow-z2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow-z3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$shadow-z4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

/** Breaking Point **/
$mobile-portrait: (
  min-width: 320px,
);
$mobile-landscape: (
  min-width: 481px,
);
$tablets: (
  min-width: 768px,
);
$laptops: (
  min-width: 1025px,
);
$desktops: (
  min-width: 1281px,
);

/** Spaces and Padding **/
$space-small: 8px;
$space-medium: 16px;
$space-large: 32px;

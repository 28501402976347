.hr-tooltip-trigger {
  position: relative;
  display: flex !important;
  justify-content: center;

  &:hover {
    .hr-tooltip-content {
      display: flex !important;
    }
  }

  .hr-tooltip-content {
    display: none !important;
    animation: fadeIn 400ms;
    position: absolute;
    z-index: 1;
    padding: 20px;
    background-color: $hr-white;
    box-sizing: content-box;
    box-shadow: $hr-shadow-z0;
    border: $hr-border;
    border-radius: $hr-border-radius;
    top: 25px; // igual a tamanho do ícone
    margin-top: 10px;
    min-width: unset;
    justify-content: center;
    align-items: center;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 155%;
      color: $hr-text-color;
    }

    &.hr-mini-tooltip {
      background-color: $hr-gray;
      padding: 6px 12px;
      width: max-content;

      span {
        font-weight: 500;
        font-size: 10px;
        line-height: 155%;
        color: $hr-white;
      }

      ::ng-deep svg-icon {
        fill: $hr-text-color;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
